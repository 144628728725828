import styles from "./Teachers.module.css"
import avatar from "../Assets/avatar.png"
import { useEffect, useState } from "react"
import axios from "axios"
import Select, { components } from "react-select"
import { Country, State, City }  from 'country-state-city';
import {Helmet} from "react-helmet";
import { analytics } from "../Analytics/Analytics"
import { logEvent } from "firebase/analytics"
import moment from "moment"

let title = "Cootz | TEACHERS"

function Teachers() {

    const [teachers, setTeachers] = useState([])
    const [subjects, setSubjects] = useState([])
    const [loading, setLoading] = useState(false)

    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [subject, setSubject] = useState('')

    let _getAllTeachers = async () => {
        setLoading(true)
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllTeachers')
        if(res.status == 200){
            setLoading(false)
            setTeachers(res.data)
        }
    }

    console.log(subjects);

    let _getAllSubjects = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllSubjects')
        if(res.status == 200){
            setSubjects(res.data)
        }
    }

    useEffect(()=>{
        _getAllTeachers()
        logEvent(analytics, 'teachers_page_view', {
            page_title: title,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
        _getAllSubjects()
    },[])

    return <div className={styles.teachers}>
        
        <Helmet>
            <title>{title}</title>
        </Helmet>
        
        <div className={styles.filters}>
        <Select placeholder="Filter by subject" options={subjects.map(i => {
            return {
                label:i.name,
                value:i.name
            }
        })} onChange={(e)=>setSubject(e.value)} components={(props) => (
            <components.Control {...props}>
                {props.children}
            </components.Control>
        )}/>

        <Select placeholder="Filter by country" options={Country.getAllCountries().map(i => {
            return {
                label: i.name,
                value: i.isoCode
            }
        })} onChange={(e)=>setCountry({
            label: e.label,
            value: e.value
         })} components={(props) => (
            <components.Control {...props}>
                {props.children}
            </components.Control>
        )}/>

        <Select placeholder="Filter by state" options={country == '' ? [] : State.getStatesOfCountry(country.value).map(i => {
            return {
                label: i.name,
                value: i.isoCode
            }
        })} onChange={(e)=>setState({
            label: e.label,
            value: e.value
        })} components={(props) => (
            <components.Control {...props}>
                {props.children}
            </components.Control>
        )}/>

        <Select placeholder="Filter by city" options={state == '' ? [] : City.getCitiesOfState(country.value, state.value).map(i => {
            return {
                label: i.name,
                value: i.name
            }
        })} onChange={(e)=>setCity(e.value)} components={(props) => (
            <components.Control style={{backgroundColor:'red'}} {...props}>
                {props.children}
            </components.Control>
        )}/>
        </div>

        <div className={styles.cardsContainer}>

          {teachers.length == '' ? <p style={{color:'#a4a4a4'}}>No teachers yet!</p> : 
           teachers.map(teacher => {
              return (
                 (teacher.country == country.label || country == '') &&
                (teacher.state == state.label ||  state == ''  ) &&
                (teacher.city == city || city == '') &&
                 (teacher.subject == subject || subject == '')  ?
                <div className={styles.card}>
               <img src={teacher.image == '' ? avatar : teacher.image} />
               <div className={styles.details}>
                   <p className={styles.name}>{teacher.name}</p>
                   <p className={styles.coaching}>{teacher.coaching}</p>
                   <p>{teacher.subject}</p>
                   <p className={styles.number}>{teacher.phone}</p>
                   <p className={styles.email}>{teacher.email}</p>
                   <p className={styles.email}>{teacher.city}, {teacher.state}, {teacher.country}</p>
                   <div className={styles.rating}>
                    {Array(teacher.rating).fill('').map(() => <i class="fa-solid fa-star"></i>)}
                   </div>
               </div>
            </div> : null
           )
        })}

        </div>
     </div>
}

export default Teachers