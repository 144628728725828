import styles from "./Contact.module.css"
import game from "../Assets/gamifying.png"

function Contact() {
    return <div className={styles.contact}>
        <img src={game} />

        <h3>Address</h3>

        <p>702-704 7th Floor Amba Tower,</p>
        <p>DC chowk, Rohini Sector 9,</p>
        <p>New Delhi, Delhi 110085</p>
        <br />
        <h3>Reach out to us at:</h3>

        <p>7011244089</p>
        
    </div>
}

export default Contact