import styles from "./Exams.module.css"
import filter from "../Assets/filter.png"
import { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router"
import {Helmet} from "react-helmet";
import placeholderImage from "../Assets/placeholder.webp"
import { logEvent } from "firebase/analytics"
import { analytics } from "../Analytics/Analytics"
import moment from "moment"

let title = "Cootz | EXAMS"

function Exams() {

    const [exams, setExams] = useState([])
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState('')
    let navigate = useNavigate();

    let _getAllCategories = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllCategories')
        if(res.status == 200){
            setCategories(res.data)
        }
    }

    let _getAllExams = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllExams')
        if(res.status == 200){
            setExams(res.data)
        }
    }

    useEffect(()=>{
        _getAllCategories()
        _getAllExams()
        logEvent(analytics, 'exams_page_view', {
            page_title: title,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
    },[])

    return <div className={styles.exams}>
        <Helmet>
            <title>{title}</title>
        </Helmet>

        <header>
            <p>All Categories</p>
            <button onClick={()=>setSelectedCategory('')} className={styles.clearBtn}>Clear Filter</button>
        </header>

       <div className={styles.cards}>
            {categories.length == 0 ? null : categories.map(category => {
                return <a href="#exams" onClick={()=>setSelectedCategory(category._id)} className={`${styles.card} ${selectedCategory == category._id ? styles.activeCard : ''}`}>
                <img style={{display:'block'}} src={!category.thumbnail || category.thumbnail == '' ? placeholderImage : category.thumbnail} />
                <div className={styles.cardBody}>
                    <p style={{fontWeight:'500',fontSize:'0.9rem'}}>{category.title}</p>
                    <p style={{fontWeight:'400',fontSize:'0.8rem'}}>{category.shortDesc.substr(0,45)}</p>
                </div>
                 </a>
            })}
         </div>

         <div className={styles.line}></div>

        <div className={styles.others}>
            <h3 id="exams">Exams</h3>
            <div className={styles.otherCards}>
                {exams.length == 0 ? null : exams.map(exam => {

                    return selectedCategory == exam.category?._id || selectedCategory == '' ? <div onClick={()=>navigate(`/exam/${exam.slug}`)} className={styles.otherCard}>
                    <img src={!exam.thumbnail || exam.thumbnail == '' ? placeholderImage : exam.thumbnail} />
                    <p style={{fontWeight:'500',fontSize:'0.9rem',padding:8}}>{exam.title.length > 40 ? `${exam.title.substr(0,45)}...` : exam.title}</p>
                </div> : null
                })}
            </div>
        </div>
    </div>
}

export default Exams