import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import "./Nav.css"
import logo from "../Assets/logo.png"
import { useLocation, useNavigate } from "react-router"

function Nav(){

    const location = useLocation()
    let navigate = useNavigate()
    let linksRef = useRef()

    let menuHandler = () => {
      if(linksRef.current.style.height == '100vh'){
        linksRef.current.style.height = '0px'
      } else {
        linksRef.current.style.height = '100vh'
      }
    }

    return <div class="nav">
    
    <img onClick={()=>navigate('/')} className="logo" src={logo} />

    <div onClick={()=>menuHandler()} className="hamburger">
        <span></span>
        <span></span>
        <span></span>
    </div>
    
    <nav ref={linksRef} className="nav-links">
         <ul>
            <li><Link className={location.pathname == '/' && "activeLink"} onClick={(e)=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to="/">Home</Link></li>
                <li><Link className={location.pathname == '/exams' && "activeLink"} onClick={()=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to="/exams" href="#">Exams</Link></li>
                <li><Link className={location.pathname == '/contests' && "activeLink"} onClick={()=>{
                }} to='/contests' href="#">Contests</Link></li>
                <li><Link className={location.pathname == '/blogs' && "activeLink"} onClick={()=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to='/blogs' href="#">Blogs</Link></li>

                {/* <li><Link className={location.pathname == '/teachers' && "activeLink"} onClick={()=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to='/teachers' href="#">Teachers</Link></li> */}

                
                <li><Link className={location.pathname == '/careers' && "activeLink"} onClick={()=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to='/careers' href="#">Careers</Link></li>

                {/* <li><Link className={location.pathname == '/scholarship' && "activeLink"} onClick={()=>{
                    if(window.matchMedia("(max-width: 600px)").matches){
                      linksRef.current.style.height = '0px'
                    }
                }} to='/scholarship' href="#">Scholarship</Link></li> */}

                <a className="navDownload" href="https://cootzlite.s3.ap-south-1.amazonaws.com/pro/Cootzprov1.0.0.apk" >Download</a>
            </ul>
        </nav>
  </div>
}

export default Nav