import styles from "./About.module.css"
import game from "../Assets/gamifying.png"

function About() {
    return <div className={styles.about}>
        <img src={game} />
        <h1>COOTZ</h1>
        <h3>India's First Educational Platform</h3>
        <p>
        Cootz is World’s First Edutainment platform that conducts online tests for various competitive exams in contest form where users will win real cash.
        </p>

        <p>
        Cootz (Cash Out Of Test Zone) brings you a supplement to your current academic activities with a mission to reroute every competitor to learning via contest test. Our aim is to improve academic performance, nullify test series burden and to improvise the Edutainment Sector.
        </p>

        <p>
        <span style={{marginBottom:'10px',fontSize:'1.4rem',color:'#8e8e8e'}}>Gamifying Education:</span><br/>
        If you have ever played a game you must have stuck at one stage that seemed very difficult to cross but those who stick to it ultimately manage to win over the hurdle. Such a practice helps develop immense persistence in a person which is one of the most crucial ingredients of success. Cootz is going to help you build your understanding of various subjects not through the process of examination but through tournament modules in education. Let's become a part of this revolution now!
        </p>

        {/* <div>
            <h4>Entrance Exam Preparation</h4>
            <p>Cootz is going to help you build your understanding of various subjects not through the process of examination but through tournament modules in education. Let's become a part of this revolution now!</p>
        </div>

        <div>
            <h4>Gaming Experience</h4>
            <p>A mind that is free and open grasps any form of knowledge in a better manner. The rote learning method fails in creating interest and is therefore not an effective way of teaching. Incorporating games in education makes the entire learning process an amazing experience for the students and for the same reason video games on various subjects like history, maths, science are becoming increasingly popular among the present generation of learners.</p>
        </div>

        <div>
            <h4>Win Cash Rewards</h4>
            <p>Experiments and researches indicate that gamification of the learning process is far more beneficial in making concepts clear and building core understanding of various subjects. Thus, we are working to build an arena where students enter to test their knowledge of subjects and win cash rewards for performing well. Result driven incentives help students help accelerate the learning process in a step by step manner.</p>
        </div> */}
    </div>
}

export default About