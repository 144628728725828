import React from 'react'
import styles from "./privacypolicy.module.css"
export default function Privacypolicy() {
    return (
        <div className={styles.container}>

<h1>Cootz</h1>	
<p>Cootz is a one stop solution for all the competitive exams in India. You no longer need to spend lakhs in coaching centres and online test series when you can receive the best questionnaires for preparation right here in Cootz.</p>
<h2>Usage of Cootz</h2>
<p>These Terms and Conditions, as well as all other rules, regulations, and terms of use referred to herein or provided by Cootz in relation to any Cootz Services, shall be applicable to any person ("User") accessing Cootz or the Cootz App (the "Cootz platform") for the purpose of participating in any of the contests (including trivia) available on the Cootz platform.</p>
<p>Cootz shall be entitled to modify these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Cootz in relation to any Cootz Services, at any time, by posting the same on Cootz. Use of Cootz constitutes the User's acceptance of such Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Cootz in relation to any Cootz Services, as may be amended from time to time. Cootz may, at its sole discretion, also notify the User of any change or modification in these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Cootz, by way of sending an email to the User's registered email address or posting notifications in the User accounts. The User may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Cootz. If such options are not exercised by the User within the time frame prescribed in the email or notification, the User will be deemed to have accepted the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Cootz.</p>
<p>Certain Cootz Services being provided on Cootz may be subject to additional rules and regulations set down in that respect. To the extent that these Terms and Conditions are inconsistent with the additional conditions set down, the additional conditions shall prevail</p>
<p>Cootz may, at its sole and absolute discretion :</p>
<p>Restrict, suspend, or terminate any User's access to all or any part of Cootz or Cootz Platform Services;</p>
<p>Change, suspend, or discontinue all or any part of the Cootz Platform Services;</p>
<p>Reject, move, or remove any material that may be submitted by a User;</p>
<p>Move or remove any content that is available on Cootz Platform;</p>
<p>Deactivate or delete a User's account and all related information and files on the account;</p>
<p>Establish general practices and limits concerning use of Cootz Platform;</p>
<p>Revise or make additions and/or deletions to the roster of players available for selection in a Contest on account of revisions to the roster of players involved in the relevant Sports Event;</p>
<p>Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment intimation of such assignment shall be sent to all Users to their registered email ids)</p>
<p>In the event any User breaches, or Cootz reasonably believes that such User has breached these Terms and Conditions, or has illegally or improperly used Cootz or the Cootz Services, Cootz may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User's access to all or any part of Cootz Contests or the Cootz Platform, deactivate or delete the User's account and all related information on the account, delete any content posted by the User on Cootz and further, take technical and legal steps as it deems necessary</p>
<p>If Cootz charges its Users a platform fee in respect of any Cootz Services, Cootz shall, without delay, repay such platform fee in the event of suspension or removal of the User's account or Cootz Services on account of any negligence or deficiency on the part of Cootz, but not if such suspension or removal is effected due to:</p>
<p>any breach or inadequate performance by the User of any of these Terms and Conditions; or</p>
<p>any circumstances beyond the reasonable control of Cootz.</p>
<p>Users consent to receiving communications such as announcements, administrative messages and advertisements from Cootz or any of its partners, licensors or associates.</p>

<h2>Intellectual Property</h2>
<p>Cootz includes a combination of content created by Cootz, its partners, affiliates, licensors, associates and/or Users. The intellectual property rights ("Intellectual Property Rights") in all software underlying Cootz and the Cootz Platform and material published on Cootz, including (but not limited to) games, Contests, software, advertisements, written content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and Flash animation, is owned by Cootz, its partners, licensors and/or associates. Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or content on Cootz either in whole or in part without express written license from Cootz</p>
<p>Users may request permission to use any Cootz content by writing in to Cootz Helpdesk.</p>
<p>Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available on Cootz ("Users' Content"). Each User represents and warrants that he/she owns all Intellectual Property Rights in the User's Content and that no part of the User's Content infringes any third party rights. Users further confirm and undertake to not display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on Cootz. Users agree to indemnify and hold harmless Cootz, its directors, employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Cootz, by such User or through the User's commissions or omissions</p>
<p>Users hereby grant to Cootz and its affiliates, partners, licensors and associates a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license to use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users' Content for any of the following purposes:</p>
<p>displaying Users' Content on Cootz</p>
<p>distributing Users' Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it, and/or</p>
<p>storing Users' Content in a remote database accessible by end users, for a charge.</p>
<p>This license shall apply to the distribution and the storage of Users' Content in any form, medium, or technology.</p>
<p>All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Cootz(s) belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to Cootz.</p>
<h2>Third Party Sites, Services and Products</h2>
<p>Cootz may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. Cootz does not exercise control over any Internet sites apart from Cootz and cannot be held responsible for any content residing in any third-party Internet site. Cootz's inclusion of third-party content or links to third-party Internet sites is not an endorsement by Cootz of such third-party Internet site.</p>
<p>Users' correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that Cootz will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third party product or service should be directed to the appropriate vendor.</p>
<p>Cootz contains content that is created by Cootz as well as content provided by third parties. Cootz does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not relied upon by the Users in utilizing the Cootz Services provided on Cootz including while participating in any of the contests hosted on Cootz.</p>


<h2>Privacy Policy</h2>
<p>All information collected from Users, such as registration and credit card information, is subject to Cootz's Privacy Policy which is available at Privacy Policy</p>

<h3>Purpose and Usage:</h3>
<p>To avail certain Services on the Portal, Users would be required to provide certain information for the registration process namely:</p>
<p>1. Username</p>
<p>2. Password</p>
<p>3. Email address</p>
<p>4. Date of birth </p>

<p>In the course of providing you with access to the Services, and in order to provide you access to the features offered through the Portal and to verify your identity, secure your account details, you may give us the permission to capture, record your device information, operating system information, network information, location information. You may also be required to furnish additional information, including your Permanent Account Number.</p>
<p>In certain instances, we may also collect Sensitive Personal Information (“SPI”) from you on the Portal. SPI means such personal information which consists of information relating to your physical, physiological and mental health condition; medical records and history; biometric information, sexual orientation and financial information, such as information regarding the payment instrument/modes used by you to make such payments, which may include cardholder name, credit/debit card number (in encrypted form) with expiration date, banking details, wallet details etc. This information is presented to you at the time of making a payment to enable you to complete your payment expeditiously.</p>
<p>Except for any financial information that you choose to provide while making payment for any Services on the Portal, Cootz does not collect any other SPI in the course of providing the Services . Any SPI collected by Cootz shall not be disclosed to any third party without your express consent, save as otherwise set out in this Privacy Policy or as provided in a separate written agreement between Cootz and you or as required by law. It is clarified that this condition shall not apply to publicly available information, including SPI, in relation to you on the Portal.</p>
<p>In the course of providing the Services, Users may invite other existing Users or other users ("Invited Users") to participate in any of the Services by providing the email address of such users. Cootz may thereafter use this information to contact the Invited User and invite such user to register with Cootz (if such Invited User is not an existing User) and participate in the Contest in relation to which such person was invited by the User. The participation of the Invited User in any of the contest shall be subject to the terms of this Privacy Policy and the Terms and Conditions for the use of the Portal. The User hereby represents that the Invited Users have consented and agreed to such disclosure to and use of their email address by Cootz.</p>
<p>All required information is specific and based on the kind of contest/ Services the User wishes to participate in or access, and will be utilized for the purpose of providing services, including but not limited to the Services requested by the User. The information as supplied by the Users enables us to improve the Services and provide you the most user-friendly game experience.</p>
<p>cootz may also share such information with affiliates and third parties in limited circumstances, including for the purpose of providing services requested by the User, complying with legal process, preventing fraud or imminent harm, and ensuring the security of our network and services.</p>

<h3>b.Disclosure/Sharing:</h3>

<p>Cootz may also share information as provided by you and data concerning usage of the Services and participation in the contst with third party service providers engaged by Cootz, for the purpose of data analytics or other similar purposes, for the purpose of storage, improving the services and helping Cootz serve you better.</p>
<p>Where we propose to use your personal information (that is, information that that may be used to identify the User and that is not otherwise publicly available) for any other uses we will ensure that we notify you first. You will also be given the opportunity to withhold or withdraw your consent for your use other than as listed above.</p>
<p>By using the Portal, you hereby expressly agree and grant consent to the collection, use and storage of this information by Cootz. Cootz reserves the right to share, disclose and transfer information collected hereunder with its own affiliates. In the event Cootz sells or transfers all or a portion of its business assets, consumer information may be one of the business assets that are shared, disclosed or transferred as part of the transaction. You hereby expressly grant consent and permission to Cootz for disclosure and transfer of information to such third parties. Cootz may share information as provided by you and data concerning usage of the Services and participation in the contest with its commercial partners for the purpose of facilitating user engagement, for marketing and promotional purposes and other related purposes. Further, Cootz reserves the right to disclose personal information as obligated by law, in response to duly authorized legal process, governmental requests and as necessary to protect the rights and interests of Cootz.</p>

<h3>c.Use of Cookies:</h3>

<p>To improve the effectiveness and usability of the Portal for our Users, we use "cookies", or such similar electronic tools to collect information to assign each visitor a unique random number as a User Identification (User ID) to understand the User's individual interests using the identified computer. Unless the User voluntarily identifies himself/herself (e.g., through registration), Cootz has no way of knowing who the User is, even if we assign a cookie to the User's computer. The only personal information a cookie can contain is information supplied by the User. A cookie cannot read data off the User's hard drive. Cootz’s advertisers may also assign their own cookies to the User's browser (if the User clicks on their ad banners), a process that Cootz does not control.</p>
<p>Cootz's web servers automatically collect limited information about User's computer's connection to the Internet, including User's IP address, when the User visits the Portal. (User's IP address is a number that lets computers attached to the Internet know where to send data to the User -- such as the web pages viewed by the User). The User's IP address does not identify the User personally. Cootz uses this information to deliver its web pages to Users upon request, to tailor its Portal to the interests of its users, to measure traffic within the Portal and let advertisers know the geographic locations from where Cootz's visitors come.</p>


<h3>d.Security Procedures:</h3>

<p>All information gathered on cootz is securely stored within cootz- controlled database. The database is stored on servers secured behind a firewall; access to such servers being password-protected and strictly limited based on need-to-know basis. However, we understand that as effective as our security measures are, no security system is impenetrable. Thus, we cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. Further, any information you include in a posting to the discussion areas will be available to anyone with Internet access. By using the Portal, you understand and agree that your information may be used in or transferred to countries other than India.</p>
<p>Cootz also believes that the internet is an ever-evolving medium. We may periodically review from time to time and change our privacy policy to incorporate such future changes as may be considered appropriate, without any notice to you. Our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be. Any changes to our privacy policy will be posted on this page, so you are always aware of what information we collect, how we use it, how we store it and under what circumstances we disclose it.</p>

<h3>e.Advertising:</h3>

<p>When cootz presents information to it's online advertisers -- to help them understand our audience and confirm the value of advertising on the Portal -- it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with Cootz, we contact you from time to time about updating your content to provide features which we believe may benefit you.</p>
<p>Several deceptive emails, websites, blogs etc. claiming to be from or associated with Cootz may or are circulating on the Internet. These emails, websites, blogs etc. often include our logo, photos, links, content or other information. Some emails, websites, blogs etc. call the user requesting the user to provide login name, password etc. or inform the user that the user has won a prize/ gift or provide a method to commit illegal/ unauthorized act or deed or request detailed personal information or a payment of some kind. The sources and contents of these emails, websites, blogs etc. and accompanying materials are in no way associated with Cootz. For your own protection, we strongly recommend not responding to such emails or using these websites, blogs etc. We may use the information provided by you to Cootz, including your email address or phone number, to contact you about the Services availed by you or to inform you of our updated Services if any.</p>

<h3>f.Retention of Data:</h3>

<p>Your personal information may be retained and may continue to be used until: (i) the relevant purposes for the use of your information described in this Privacy Policy are no longer applicable; and (ii) we are no longer required by applicable law, regulations, contractual obligations or legitimate business purposes to retain your personal information and the retention of your personal information is not required for the establishment, exercise or defense of any legal claim.</p>

<h3>g.User Account and Data Deletion:</h3>

<p>Users are entitled to request Cootz to delete their User accounts and their personal information by sending an email with their written request to helpdesk@cootz.in</p>

<p>We will do our best to respond promptly and in any event within one month of the following:</p>
<ul>
	<li>Our receipt of your written request; or</li>
	<li>Our receipt of any further information we may ask you to provide to enable us to comply with your request, whichever is later.</li>
</ul>

<p>As an alternative to account deletion, by writing to helpdesk@cootz.in, you also have the option to request either:</p>
<ul>
	<li>(i) the suspension of your account, after which you will not be able to play paid contests on your User account but will continue to have access to other parts of the Portal; or</li>
	<li>(ii) temporary deactivation of your account, where you will no longer be able to log into the Portal but which allows you to request reactivation of your account with all your account data.</li>
</ul>

<p>If you proceed with permanent deactivation of your account, you will lose access to the Portal and the Services, including any User data and personal information associated with your account. Users may request for account restoration within six (6) months from the date of notification of account deletion by Dream11 by writing to helpdesk@cootz.in</p>
<p>When you request deletion of your data, we follow a deletion process that ensures that your data is safely and completely removed from our servers or retained only in anonymised form. We try to ensure that our services protect information from accidental or malicious deletion. Because of this, there may be delays between when you request deletion and when copies are deleted from our active and backup systems.</p>

<h3>h.Applicable Law and Jurisdiction:</h3>

<p>By visiting this Portal, you agree that the laws of the Republic of India without regard to its conflict of laws principles, govern this Privacy Policy and any dispute arising in respect hereof shall be subject to and governed by the dispute resolution process set out in the Terms and Conditions.</p>



<h2>User Conduct</h2>
<p>Users agree to abide by these Terms and Conditions and all other rules, regulations and terms of use of the Website. In the event User does not abide by these Terms and Conditions and all other rules, regulations and terms of use, Cootz may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:</p>
<p>restricting, suspending, or terminating any User's access to all or any part of Cootz Services;</p>
<p>deactivating or deleting a User's account and all related information and files on the account. Any amount remaining unused in the User's Game account or Winnings Account on the date of deactivation or deletion shall be transferred to the User's bank account on record with Cootz subject to a processing fee (if any) applicable on such transfers as set out herein; or</p>
<p>refraining from awarding any prize(s) to such User.</p>
<p>Users agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by Cootz). Users further agree to update and keep updated their registration information</p>
<p>A User shall not register or operate more than one User account with Cootz.</p>
<p>Users agree to ensure that they can receive all communication from Cootz by marking e-mails or sending SMSs from Cootz as part of their "safe senders" list. Cootz shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User's junk or spam folder.</p>
<p>Any password issued by Cootz to a User may not be revealed to anyone else. Users may not use anyone else's password. Users are responsible for maintaining the confidentiality of their accounts and passwords. Users agree to immediately notify Cootz of any unauthorized use of their passwords or accounts or any other breach of security.</p>
<p>Users agree to exit/log-out of their accounts at the end of each session. Cootz shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.</p>
<p>Users agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software designed to modify or interfere with Cootz Services and/or Cootz experience or assist in such activity.</p>
<p>Users agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying Cootz or Cootz’s Services.</p>
<p>Users agree that without Cootz's express written consent, they shall not modify or cause to be modified any files or software that are part of Cootz's Services.</p>
<p>Users agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support Cootz or the Cootz’s Services (each a "Server"); or (2) the enjoyment of Cootz Services by any other User or person.</p>
<p>Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt Cootz Services or any other person's use or enjoyment of Cootz Services.</p>
<p>Users shall not attempt to gain unauthorised access to the User accounts, Servers or networks connected to Cootz Services by any means other than the User interface provided by Cootz, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of Cootz Services.</p>
<p>User shall not to publish any content that is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person.</p>
<p>Without limiting the foregoing, Users agree not to use Cootz for any of the following:</p>
<p>To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;</p>
<p>To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;</p>
<p>To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;</p>
<p>To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, Cootz, any software, hardware, or telecommunications equipment;</p>
<p>To advertise, offer or sell any goods or services for any commercial purpose on Cootz without the express written consent of Cootz;</p>
<p>To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;</p>
<p>To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;</p>
<p>To restrict or inhibit any other user from using and enjoying any public area within our sites;</p>
<p>To collect or store personal information about other Users;</p>
<p>To interfere with or disrupt Cootz, servers, or networks;</p>
<p>To impersonate any person or entity, including, but not limited to, a representative of Cootz, or falsely state or otherwise misrepresent User's affiliation with a person or entity;</p>
<p>To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through Cootz or to manipulate User's presence on Cootz(s);</p>
<p>To take any action that imposes an unreasonably or disproportionately large load on our infrastructure;</p>
<p>To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum.</p>
<p>To engage in any action that threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation.</p>
<p>If a User chooses a username that, in Cootz's considered opinion is obscene, indecent, abusive or that might subject Cootz to public disparagement or scorn, or a name which is an official team/league/franchise names and/or name of any sporting personality, as the case may be, Cootz reserves the right, without prior notice to the User, to restrict usage of such names, which in Cootz’s opinion fall within any of the said categories and/or change such username and intimate the User or delete such username and posts from Cootz, deny such User access to Cootz, or any combination of these options.</p>
<p>Unauthorized access to Cootz is a breach of these Terms and Conditions, and a violation of the law. Users agree not to access Cootz by any means other than through the interface that is provided by Cootz for use in accessing Cootz. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.</p>
<p>Use of Cootz is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit Cootz's right to comply with governmental, court, and law-enforcement requests or requirements relating to Users' use of Cootz.</p>
<p>Users may reach out to Cootz through -</p>
<p>Helpdesk if the user has any concerns with regard to a Contest and/or contest within Forty Eight (48) hours of winner declaration for the concerned contest.</p>
<p>Persons below the age of eighteen (18) years are not allowed to participate on any of the contests, games (by whatever name called) on the Cootz Platform. The Users will have to disclose their real age at the time of getting access into the Cootz Platform.</p>
<p>Cootz may not be held responsible for any content contributed by Users on the Cootz.</p>

<h2>Conditions of Participation:</h2>

<p>By entering a Contest, user agrees to be bound by these Terms and the decisions of Cootz. Subject to the terms and conditions stipulated herein below, the Company, at its sole discretion, may disqualify any user from a Contest, refuse to award benefits or prizes and require the return of any prizes, if the user engages in unfair conduct, which the Company deems to be improper, unfair or otherwise adverse to the operation of the Contest or is in any way detrimental to other Users which includes, but is not limited to:</p>
<p>Falsifying ones’ own personal information (including, but not limited to, name, email address, bank account details and/or any other information or documentation as may be requested by Cootz to enter a contest and/or claim a prize/winning.;</p>
<p>Engaging in any type of financial fraud or misrepresentation including unauthorized use of credit/debit instruments, payment wallet accounts etc. to enter a Contest or claim a prize. It is expressly clarified that the onus to prove otherwise shall solely lie on the user.;</p>
<p>Colluding with any other user(s) or engaging in any type of syndicate play;</p>
<p>Any violation of Contest rules or the Terms of Use;</p>
<p>Accumulating points or prizes through unauthorized methods such as automated bots, or other automated means;</p>
<p>Using automated means (including but not limited to harvesting bots, robots, parser, spiders or screen scrapers) to obtain, collect or access any information on the Website or of any User for any purpose</p>
<p>Any type of Cash Bonus misuse, misuse of the Invite Friends program, or misuse of any other offers or promotions;</p>
<p>Tampering with the administration of a Contest or trying to in any way tamper with the computer programs or any security measure associated with a Contest;</p>
<p>Obtaining other users’ information without their express consent and/or knowledge and/or spamming other users (Spamming may include but shall not be limited to send unsolicited emails to users, sending bulk emails to Cootz Users, sending unwarranted email content either to selected Users or in bulk); or</p>
<p>Abusing the Website in any way (‘unparliamentary language, slangs or disrespectful words’ are some of the examples of Abuse)</p>
<p>It is clarified that in case a User is found to be in violation of this policy, Cootz reserves its right to initiate appropriate Civil/Criminal remedies as it may be advised other than forfeiture and/or recovery of prize money if any.</p>
<h2>Registration for a contest</h2>
<p>Participants are also required to confirm that they have read, and shall abide by, these Terms and Conditions.</p>
<p>Once the Participants have entered the above information, and clicked on the "register" tab, and such Participants are above the age of 18 years, they are sent an email confirming their registration and containing their login information.</p>
<h2>Contest(s), Participation and Prizes</h2>
<p>As part of its services, Cootz may make available the contest(s) on the Cootz platform.</p>
<p>Currently, Exam contests and Trivia are made available on Cootz platform.</p>
<p>Individual users wishing to participate in the such contest ("“Participants"”) are invited. Cootz offers its platform to Participants for Contest(s) being created relating to Exam and Trivia, and Participants can participate in such Contest(s) with their Teams.</p>
<p>Cootz reserves the right to abandon a specific round or adjust the deadline of a round in certain specific, uncertain scenarios, which are beyond Cootz’s reasonable control, including but not limited to the ones’ mentioned herein below:</p>

<h3>1.	Actual Contest start time is before the official deadline:</h3>
<p>Cootz reserves the right to adjust the deadline to a maximum of 10 minutes post actual Contest start time. In case the actual Contest start time is more than 10 minutes of official deadline, the contest will be abandoned.</p>
<p>Cootz reserves the right to adjust the deadline by a Maximum of 10 minutes, whichever is less, before the official contest start time.</p>
<p>In cases where official contest time cannot be verified by Cootz through reliable and/or publicly available sources, Cootz reserves the right to adjust the deadline to such a time by which a maximum of 3 overs in the given Contest are bowled.</p>
<h3>2.	Actual Contest start time is after the official deadline:</h3>

<p>Cootz reserves the right to extend the deadline or abandon the contest/game based on the circumstances such as interruption on account of weather, non-appearance of teams, technical/equipment glitches causing delays etc.</p>
Cootz shall endeavour to send communications through emails and/or SMS communication, about any such change as is contemplated in the aforementioned paragraphs to keep the User updated.
<p>Playeris awarded points on the basis of the real Contest or exam of the Contest(s). In certain pre-specified Contests, there may be more than one Winner and distribution of prizes to such Winners will be in increasing order of their Team's aggregate score at the end of the designated Contest(s) of the Contests.</p>
<p>Other rules and regulations (including rules and regulation in relation to any payments made to participate in the Contest(s); and all Participants agree to abide by the same.</p>
<p>Currently, there are paid versions of the Contest(s) made available on Cootz platform. Users may participate in the Contest(s) by paying the pre-designated amount as provided on the relevant Contest page. The ‘pre-designated amount’ means and includes pre-determined platform fee for accessing Cootz services and pre-determined participant’s contribution towards prize money pool. The Participant with the highest aggregate points at the end of the pre-determined Contest shall be eligible to win a pre-designated prize which is disbursed out of prize money pool, as stated on the relevant Contest(s) page.</p>
<p>A Participant may create different Teams for participation in Contest(s) in relation to a Sport Event across the Cootz Services. However, unless Cootz specifies otherwise in relation to any Contest ("“Multiple Entry Contest"”), Participants acknowledge and agree that they may enter only one Team in any Contest offered in relation to a Sport Event. In case of Multiple Entry Contest(s), a Participant may enter more than one Team in a single Multiple Entry Contest. In addition, it is expressly clarified that Cootz may, from time to time, restrict the maximum number of Teams that may be created by a single User account (for each format of the contest) or which a single User account may enter in a particular Multiple Entry Contest, in each case to such number as determined by Cootz in its sole discretion.</p>
<p>Participant shall pay a pre-designated amount for participating in the contest(s) being created on the Cootz platform.</p>
<p>In two members and above public contests, where all participants have entered the contest with the exact same, contest prize money shall be equally divided amongst all participants and the amount shall be deposited in the Cootz winning account of all participants and the remaining amount shall be credited in the Cash Bonus account of such participants. In the event a user is found to be violating this policy, the Company reserves the right to initiate appropriate action against such users as it deems fit, which shall also include forfeiture of the Cash Bonus Amount.</p>
<p>On the basis of information/data received through feed providers, publicly available information. Users are advised to do a thorough research of their own from official sources and/or other available sources of information. Cootz, shall not take any liability, if a player earlier indicated as 'Playing' does not play or start for any reason whatsoever.</p>

<h2>Contest Formats</h2>

<p>Currently two formats of contest(s) are made available on Cootz platform (1) Public Contest where Users can participate in a Contest with other Users without any restriction on participation </p>
<p>Public contest</p>
<p>In the Public contest format of the Contest(s), Cootz may make available the Contest(s) comprising of 2 -– 100 Participants or any other pre-designated number of Participants.</p>
<p>Cootz may create this format of the Contest(s) as a paid format and the Winner will be determinable at the end of the Contest as per rule of the contests.</p>
<p>The number of Participants required to make the Contest(s) operational will be pre-specified and once the number of Participants in such Contest(s) equals the pre-specified number required for that Contest(s), such Contest(s) shall be operational. In case the number of Participants is less than the pre-specified number at the time of commencement of the Contest, such Contest(s) will not be operational and the pre-designated amount paid by each Participant shall be returned to the account of such User without any charge or deduction.</p>
<p>In certain Contests across the Cootz Services, designated as “Guaranteed contests”, the Contest(s) shall become operational only when a minimum of two users join a Guaranteed Contest. The pre-specified number of winners to be declared in such Contest(s), even if all available Participant slots (as pre-specified in relation to the Contest(s)) remain unfilled. It is clarified that notwithstanding the activation of such Contest(s), Participants can continue to join such Contest(s) till either (i) all available Participant slots of such Contest(s) are filled or (ii) the Contest to which the Contest (s) relates commences, whichever is earlier. In the event of shortfall in the number of participants joining the Guaranteed Contest, Cootz shall continue with such contests and the short fall in the prize pool shall be borne by Cootz.</p>
<p>Games of skill are legal, as they are excluded from the ambit of Indian gambling legislations including, the Public Gambling Act of 1867.The Indian Supreme Court in the cases of State of Andhra Pradesh v. K Satyanarayana (AIR 1968 SC 825) and KR Lakshmanan v. State of Tamil Nadu (AIR 1996 SC 1153) has held that a game in which success depends predominantly upon the superior knowledge, training, attention, experience and adroitness of the player shall be classified as a game of skill.</p>
<p>The Contest (s) described above (across the Cootz Services) are games of skill as success of Participants depends primarily on their superior knowledge of the exam and general knowledge, attention and dedication towards the Contest(s) and adroitness in playing the Contest(s). The Contest(s) also requires Participants to field well-balanced sides with limited resources and make substitutions at appropriate times to gain the maximum points.</p>
<p>By participating in this Contest(s), each Participant acknowledges and agrees that he/she is participating in a game of skill.</p>
<h2>Eligibility</h2>
<p>Cootz may on receipt of information bar a person from participation and/or withdrawing winning amounts if such person is found to be one with insider knowledge of participating teams in any given contests/Contest, organizing boards, leagues etc.</p>
<p>Only those Participants who have successfully registered on the Cootz as well as registered prior to each Contest in accordance with the procedure outlined above shall be eligible to participate in the Contest and win prizes.</p>

<h2>Payment Terms</h2>

<p>In respect of any transactions entered into on the Cootz platform, including making a payment to participate in the paid versions of Contest(s), Users agree to be bound by the following payment terms:</p>
<p>The payment of pre-designated amount Users make to participate in the Contest(s) is inclusive of the pre-designated platform fee for access to the Cootz Services charged by Cootz and pre-determined participant’s contribution towards prize money pool.</p>
<p>Subject to these Terms and Conditions, all amounts collected from the User are held in a separate non-interest earning bank Accounts. The said accounts are operated by a third party appointed by Cootz in accordance with Clause 10 of these Terms and Conditions. From these bank accounts, the payouts can be made to (a) Users (towards their withdrawals), (b) Cootz (towards its Platform Fees) and to (c) Government (towards TDS on Winnings Amount). Cootz receives only its share of the platform Fees through the said Escrow Agent.</p>
<p>The Cootz reserves the right to charge a Platform Fee, which would be specified and notified by Cootz on the Contest page, being created on Cootz platform, prior to a User's joining of such Contest. The Platform Fee (inclusive of applicable tax thereon) will be debited from the User’s account balance and Cootz shall issue an invoice for such debit to the User.</p>
<p>The User may participate in a Contest wherein the User has to contribute a pre-specified contribution towards the Prize Money Pool of such Contest, which will be passed on to the Winner(s) of the Contest after the completion of the Contest as per the terms and conditions of such Contest. It is clarified that Cootz has no right or interest in this Prize Money Pool, and only acts as an intermediary engaged in collecting and distributing the Prize Money Pool in accordance with the Contest terms and conditions. The amount to be paid-in by the User towards the Prize Money Pool would also be debited from the User’s account balance maintained with the Trustee.</p>
<p>Any user availing Cootz services are provided with two categories of accounts for the processing and reconciliation of payments: (i) 'Unutilized' Account, (ii) Winnings Account. It is clarified that in no instance the transfer of any amounts in the User's accounts to any other category of account held by the user or any third party account, including a bank account held by a third party:</p>
<p>User's winnings in any Contest will reflect as credits to the User's Winnings Account.</p>
<p>User’s remitting the amount through the designated payment gateway shall be credited to User’s Unutlized Account’.</p>
	<p>Each time a User participates in any contest on Cootz platform, the pre-designated amount shall be debited in the User’s account. In debiting amounts from the User’s accounts towards the pre-designated amount of such user shall be debited from the User’s Unutilized Account and thereafter, any remaining amount of participation fee shall be debited from the User’s Winning Account.</p>
	<p>In case there is any amount remaining to be paid by the User in relation to such User’s participation in any Contest(s) or Contest(s), the User will be taken to the designated payment gateway to give effect to such payment. In case any amount added by the User through such payment gateway exceeds the remaining amount of the pre-designated amount, the amount in excess shall be transferred to the User’s ‘Unutilized’ Account and will be available for use in participation in any Contest(s) or Contest(s).</p>
	<p>Debits from the ‘Unutilized’ Account for the purpose of enabling a user’s participation in a Contest shall be made in order of the date of credit of amounts in the ‘Unutilized’ Account, and accordingly amounts credited into ‘Unutilized’ Account earlier in time shall be debited first.</p>
<p>The date of credit of such amount, Cootz reserves the right to forfeit such unutilised amount, without liability or obligation to pay any compensation to the User.</p>
	<p>Withdrawal of any amount standing to the User's credit in the Winnings Account may be made by way of a request to Cootz. Cootz shall affect an online transfer to the User's bank account on record with Cootz within a commercially reasonable period of time. Such transfer will reflect as a debit to the User's Winnings Account. Cootz shall not charge any processing fee for the online transfer of such amount from the Winnings Account to the User's bank account on record with Cootz. Users are requested to note that they will be required to provide valid photo identification and address proof documents for proof of identity and address in order for Cootz to process the withdrawal request. The name mentioned on the User's photo identification document should correspond with the name provided by the User at the time of registration on Cootz, as well as the name and address existing in the records of the User's bank account as provided to Cootz. In the event that no bank account has been registered by the User against such User's account with Cootz, or the User has not verified his/her User account with Cootz, to Cootz's satisfaction and in accordance with these Terms and Conditions, and in case the User fails to register a bank account with his/her User Account and/or to verify his/her User Account, Cootz shall not transfer any Winning amounts to the User.</p>
	<p>Further, in order to conduct promotional activities, Cootz may gratuitously issue Cash Bonus to the User for the purpose of participation in any Contest(s) and no User shall be permitted to transfer or request the transfer of any amount in to the Cash Bonus. The usage of any Cash Bonus issued shall be subject to the limitations and restrictions, including without limitation, restrictions as to time within which such Cash Bonus must be used, as applied by Cootz and notified to the User at the time of issue of such amount. The issue of any Cash Bonus to the user is subject to the sole discretion of Cootz and cannot be demanded by any User as a matter of right. The issue of any Cash Bonus by Cootz on any day shall not entitle the user to demand the issuance of such Cash Bonus at any subsequent period in time nor create an expectation of recurring issue of such Cash Bonus by Cootz to such User. The Cash Bonus granted to the user may be used by such User for the purpose of setting off against the contribution to prize pool in any Contest, in accordance with these Terms and Conditions. The Cash Bonus shall not be withdraw-able or transferrable to any other account of the User, including the bank account of such User, or of any other User or person, other that as part of the winnings of a User in any Contest(s). In case the User terminates his/her account with Cootz or such account if terminated by Cootz, all Cash Bonus granted to the user shall return to Cootz and the User shall not have any right or interest on such points.</p>
	<p>Users agree that once they confirm a transaction on Cootz, they shall be bound by and make payment for that transaction.</p>
	<p>The User acknowledges that subject to time taken for bank reconciliations and such other external dependencies that Cootz has on third parties, any transactions on Cootz Platform may take up to 24 hours to be processed. Any amount paid or transferred into the User's 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Similarly, the utilization of the Cash Bonus or money debited from 'Unutilized' Account or Winnings Account may take up to 24 hours to reflect in the User's 'Unutilized' Account or Winnings Account balance. Users agree not to raise any complaint or claim against Cootz in respect of any delay, including any lost opportunity to join any Contest or Contest due to delay in crediting of transaction amount into any of the User's accounts</p>
A transaction, once confirmed, is final and no cancellation is permissible.
Cootz may, in certain exceptional circumstances and at its sole and absolute discretion, refund the amount to the User after deducting applicable cancellation charges and taxes. At the time of the transaction, Users may also be required to take note of certain additional terms and conditions and such additional terms and conditions shall also govern the transaction. To the extent that the additional terms and conditions contain any clause that is conflicting with the present terms and conditions, the additional terms and conditions shall prevail.
	Tabulation of fantasy points
Cootz may obtain the score feed and other information required for the computation and tabulation of fantasy points from third party service provider(s) and/or official website of the Contest organiser. In the rare event that any error in the computation or tabulation of fantasy points, selection of winners, abandonment of a Contest etc., as a result of inaccuracies in or incompleteness of the feed provided by the third party service provider and/or official website of the Contest organiser comes to its attention, Cootz shall use best efforts to rectify such error prior to the distribution of prizes. However, Cootz hereby clarifies that it relies on the accuracy and completeness of such third party score/statistic feeds and does not itself warrant or make any representations concerning the accuracy thereof and, in any event, shall take no responsibility for inaccuracies in computation and tabulation of fantasy points or the selection of winners as a result of any inaccurate or incomplete scores/statistics received from such third party service provider. Users and Participants agree not to make any claim or raise any complaint against Cootz in this respect.
	Selection and Verification of Winners and Conditions relating to the Prizes
	Selection of Winners
Winners will be decided on the basis of the scores of the Teams in a designated Contest (which may last anywhere between one day and an entire tournament) of the Contest(s). The Participant(s) owning the Team(s) with the highest aggregate score in a particular Contest shall be declared the Winner(s). In certain pre-specified Contests, Cootz may declare more than one Winner and distribute prizes to such Winners in increasing order of their Team's aggregate score at the end of the designated Contest of the Contest. The contemplated number of Winners and the prize due to each Winner in such Contest shall be as specified on the Contest page prior to the commencement of the Contest.
Participants creating Teams on behalf of any other Participant or person shall be disqualified.
In the event of a tie, the winning Participants shall be declared Winners and the prize shall be equally divided among such Participants.
Cootz shall not be liable to pay any prize if it is discovered that the Winner(s) have not abided by these Terms and Conditions, and other rules and regulations in relation to the use of the Cootz, Contest, "Cootz Rules", etc.
	Contacting Winners
Winners shall be contacted by Cootz or the third party conducting the Contest on the e-mail address provided at the time of registration. The verification process and the documents required for the collection of prize shall be detailed to the Winners at this stage. As a general practice, winners will be required to provide following documents:
	Photocopy of the User's PAN card;
	Photocopy of a government-issued residence proof;
	User's bank account details and proof of the same.
Cootz shall not permit a Winner to withdraw his/her prize(s)/accumulated winnings unless the above-mentioned documents have been received and verified within the time-period stipulated by Cootz. The User represents and warrants that the documents provided in the course of the verification process are true copies of the original documents to which they relate.
<br/>
Participants are required to provide proper and complete details at the time of registration. Cootz shall not be responsible for communications errors, commissions or omissions including those of the Participants due to which the results may not be communicated to the Winner.
<br/>
The list of Winners shall be posted on a separate web-page on the Cootz. The winners will also be intimated by e-mail.
<br/>
In the event that a Participant has been declared a Winner on the abovementioned web-page but has not received any communication from Cootz, such Participant may contact Cootz within the time specified on the webpage.
	Verification process
Only those Winners who successfully complete the verification process and provide the required documents within the time limit specified by Cootz shall be permitted to withdraw/receive their accumulated winnings (or any part thereof). Cootz shall not entertain any claims or requests for extension of time for submission of documents.
Cootz shall scrutinize all documents submitted and may, at its sole and absolute discretion, disqualify any Winner from withdrawing his accumulated winnings (or any part thereof) on the following Contests:
	Determination by Cootz that any document or information submitted by the Participant is incorrect, misleading, false, fabricated, incomplete or illegible; or
	Participant does not fulfill the Eligibility Criteria specified in Clause 10 above; or
	Any other Contest.
	Taxes Payable<br/>
All prizes shall be subject to deduction of tax (“TDS”) as per the Income Tax Act 1961. The TDS rate prescribed by the Government of India with respect to any prize money amount that is in excess of Rs. 10,000/- is 30% . TDS of 30% shall be deducted on Net Winnings ( prize money amount - contest entry amount - cash bonus). In case of any revisions by the Government of India to the aforementioned rate in the future, TDS will be deducted by Cootz in accordance with the then current prescribed TDS rate. Winners will be provided TDS certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the prize money.
	Miscellaneous<br/>
The decision of Cootz with respect to the awarding of prizes shall be final, binding and non-contestable.
<br/>
Participants playing the paid formats of the Contest(s) confirm that they are not residents of any of the following Indian states - Andhra Pradesh, Assam, Nagaland, Odisha, Sikkim or Telangana. If it is found that a Participant playing the paid formats of the Contest(s) is a resident of any of the abovementioned states, Cootz shall disqualify such Participant and forfeit any prize won by such Participant. Further Cootz may, at its sole and absolute discretion, suspend or terminate such Participant's account with Cootz. Any amount remaining unused in the User's Game Account or Winnings Account on the date of deactivation or deletion shall be reimbursed to the User by an online transfer to the User's bank account on record with Cootz, subject to the processing fee (if any) applicable on such transfers as set out herein.
<br/>
If it is found that a Participant playing the paid formats of the Contest(s) is under the age of eighteen (18), Cootz shall be entitled, at its sole and absolute discretion, to disqualify such Participant and forfeit his/her prize. Further, Cootz may, at its sole and absolute discretion, suspend or terminate such Participant's account.
<br/>
To the extent permitted by law, Cootz makes no representations or warranties as to the quality, suitability or merchantability of any prizes and shall not be liable in respect of the same.
<br/>
Cootz may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Participants shall not raise any claim against Cootz or question its right to modify such prizes being offered, prior to closure of the Contest.
<br/>
Cootz will not bear any responsibility for the transportation or packaging of prizes to the respective winners. Cootz shall not be held liable for any loss or damage caused to any prizes at the time of such transportation.
<br/>
The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes.
<br/>
The Winners shall bear all transaction charges levied for delivery of cash prizes.
<br/>
All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.

<br />
<br />
<h2>Refund Policy</h2>
<p>We will try our best to create the best user experience for our users.</p>
<p>If paid by any source account refunds will be issued to the source provided at the time of purchase and in case of payments made through a payment gateways, payments refund will be made to the the same account .</p>
<p>Deposits</p>
<p>In order to comply with extant law relating to deposit regulations, please note that the user must withdraw any idle balance lying in their 'Winnings Wallet' within 365 days of having won that amount. Similarly, users must use any money in their 'Deposit Cash Wallet' within 365 days. If not, the said amounts will have to be forfeited.</p>

	Publicity<br/>
Acceptance of a prize by the Winner constitutes permission for Cootz, and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever.
<br/>
The Winners further undertake that they will be available for promotional purposes as planned and desired by Cootz without any charge. The exact dates remain the sole discretion of Cootz. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions.
	General Conditions<br/>
If it comes to the notice of Cootz that any governmental, statutory or regulatory compliances or approvals are required for conducting any Contest(s) or if it comes to the notice of Cootz that conduct of any such Contest(s) is prohibited, then Cootz shall withdraw and / or cancel such Contest(s) without prior notice to any Participants or winners of any Contest(s). Users agree not to make any claim in respect of such cancellation or withdrawal of the Contest or Contest it in any manner.
<br/>
Employees, directors, affiliates, relatives and family members of Cootz, will not be eligible to participate in any Contest(s).<br/>
	Dispute and Dispute Resolution<br/>
	The courts of competent jurisdiction at Mumbai shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with, the Cootz Services provided by Cootz (including the Contest(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or Cootz, as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of the Republic of India.
	In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.
	All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time.
	The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.
	Nothing contained in these Terms and Conditions shall prevent Cootz from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard Cootz's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Cootz to pursue any remedy for monetary damages through the arbitration described herein.
	Release and Limitations of Liability<br/>
	Users shall access the Cootz Services provided on Cootz voluntarily and at their own risk. Cootz shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to the Cootz Services (including participation in the Contest(s)) or as a result of acceptance of any prize.
	By entering the contests and accessing the Cootz Services provided therein, Users hereby release from and agree to indemnify Cootz, and/ or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the Cootz Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.
	Cootz accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes.
	Users shall be solely responsible for any consequences which may arise due to their access of Cootz Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to Cootz Services, including provision of incorrect address or other personal details. Users also undertake to indemnify Cootz and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by Cootz on account of such act on the part of the Users.
	Users shall indemnify, defend, and hold Cootz harmless from any third party/entity/organization claims arising from or related to such User's engagement with the Cootz or participation in any Contest. In no event shall Cootz be liable to any User for acts or omissions arising out of or related to User's engagement with the Cootz or his/her participation in any Contest(s).
	In consideration of Cootz allowing Users to access the Cootz Services, to the maximum extent permitted by law, the Users waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against Cootz, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of Cootz Services or related to the Contests or the prizes of the Contests.
	Disclaimers<br/>
	To the extent permitted under law, neither Cootz nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or material.
	To the extent permitted under law, Cootz shall not be responsible for any harm resulting from downloading or accessing any information or material, the quality of servers, games, products, Cootz services or sites, cancellation of competition and prizes. Cootz disclaims any responsibility for, and if a User pays for access to one of Cootz's Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by Cootz's maintenance on the servers or the technology that underlies our sites, failures of Cootz's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, Cootz does not provide any warranty as to the content on the Cootz(s). Cootz(s) content is distributed on an "as is, as available" basis.
	Any material accessed, downloaded or otherwise obtained through Cootz is done at the User's discretion, competence, acceptance and risk, and the User will be solely responsible for any potential damage to User's computer system or loss of data that results from a User's download of any such material.
	Cootz shall make best endeavours to ensure that the Cootz(s) is error-free and secure, however, neither Cootz nor any of its partners, licensors or associates makes any warranty that:
	the Cootz(s) will meet Users' requirements,<br/>
	Cootz(s) will be uninterrupted, timely, secure, or error free
	the results that may be obtained from the use of Cootz(s) will be accurate or reliable; and
	the quality of any products, Cootz Services, information, or other material that Users purchase or obtain through Cootz.com(s) will meet Users' expectations.
	In case Cootz discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, Cootz reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, Cootz agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same.
	To the extent permitted under law, neither Cootz nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.
	Any Cootz Services, events or Contest(s) being hosted or provided, or intended to be hosted on Cootz platform and requiring specific permission or authority from any statutory authority or any state or the central government, or the board of directors shall be deemed cancelled or terminated, if such permission or authority is either not obtained or denied either before or after the availability of the relevant Cootz Services, events or Contest(s) are hosted or provided.
	To the extent permitted under law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever.
	Users personal information shall only be used where General Data Protection Rules allows Cootz to do so. User may at any time withdraw their consent and request deletion of their personal information by writing to Cootz Helpdesk and once Cootz has received the user notification, Cootz will endeavour to delete or de-identify the user information and will no longer use users personal information unless Cootz has a legitimate basis for doing so by law.
	The Participant(s) owning the Team(s) with the highest aggregate score in a particular Contest shall be declared the Winners. In the event of more than one participating team having the same team score, the Winner will be decided on the basis of points by earned by the captain of such competing team. If the participating team(s) have the same captain, then the participating team with the vice -captain having higher score shall be declared a Winner. In the event of participating team(s) having the same captain and vice-captain, the Winner will be declared based on the Participant submitting their team first.
	All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.
	ICC shall be solely responsible for distribution of prizes for all the IBC Cootz Contest winners. Cootz shall not be held responsible for any lapses on part of ICC to give the prizes.
	Miscellaneous<br/>
	Cootz may be required under certain legislations, to notify User(s) of certain events. User(s) hereby acknowledge and consent that such notices will be effective upon Cootz posting them on the Cootz or delivering them to the User through the email address provided by the User at the time of registration. User(s) may update their email address by logging into their account on the Cootz. If they do not provide Cootz with accurate information, Cootz cannot be held liable for failure to notify the User.
	Cootz shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Cootz's control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation of any Contest to which a Contest relates. In such circumstances, Cootz shall also be entitled to cancel any related Contest(s) and to process an appropriate refund for all Participants.
	Cootz's failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.
	Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Cootz or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.
	If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.
	Cootz reserves the right to moderate, restrict or ban the use of the Cootz, specifically to any User, or generally, in accordance with Cootz's policy/policies from time to time, at its sole and absolute discretion and without any notice.
	Cootz may, at its sole and absolute discretion, permanently close or temporarily suspend any Cootz Services (including any Contest(s)).
	Cootz may from time to time conduct/organize, promotions/offers on the platform. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on the Cootz platform. Also, promotions/offers cannot be clubbed with Cash Bonus available with any user.
	Grievance Redressal Mechanism<br/>
	In case you have any complaints or grievance pertaining to (i) any User Content that you believe violates these Terms (other than an infringement of Intellectual Property Rights) for the reporting mechanism for such claims), (ii) your access to the Platform or (iii) any User Content which you believe is, prima facie, in the nature of any material which is obscene, defamatory towards the complainant or any person on whose behalf you are making the complaint, or is in the nature of impersonation in an electronic form, including artificially morphed images of such individual, please share the same with us by writing to: grievanceofficer@Cootz.com
	In your complaint or grievance, please include the following information:
	Your name and contact details: name, address, contact number and email address;
	Your relation to the subject matter of the complaint, i.e. complainant or person acting on behalf of an affected person;
	The name and age of the person aggrieved or affected by the subject matter of the complaint, in case you are acting on behalf of such person and a statement, under penalty of perjury, that you are authorised to act on behalf of such person and to provide such person's personal information to us in relation to the complaint/grievance;
	Description of the complaint or grievance with clear identification of the User Content in relation to which such complaint or grievance is made;
	A statement that you believe, in good faith, that the User Content violates these Terms
	A statement that the information provided in the complaint or grievance is accurate.
	The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners. You are requested to send us a written notice/ intimation if you notice any act of infringement on the Platform, which must include the following information:
	A clear identification of the copyrighted work allegedly infringed;
	A clear identification of the allegedly infringing material on the Platform;
	Your contact details: name, address, e-mail address and phone number;
	A statement that you believe, in good faith, that the use of the copyrighted material allegedly infringed on the Platform is not authorized by your agent or the law;
	A statement that the information provided in the notice is accurate, and under penalty of perjury, that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;
	Your signature or a signature of your authorized agent.
The aforesaid notices can be sent to the Company by email at grievanceofficer@Cootz.com.
	On receiving such complaint, grievance or notice, the Company reserves the right to investigate and/or take such action as the Company may deem appropriate. The Company may reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint, grievance or notice, and you acknowledge that your timely assistance with the investigation would facilitate the redressal of the same.
	The name and title of the Grievance Redressal Officer is as follows:
The Grievance Officer identified above pursuant to the provisions of applicable laws including but not limited to the Information Technology Act, 2000 and the Consumer Protection Act, 2019, and the Rules enacted under those laws.
The Company reserves the right to replace the Grievance Redressal Officer at its discretion through publication of the name and title of such replacement on the website, which replacement shall come into effect immediately upon publication.
	Standard Terms and Conditions of "Invite Friends" program.
The Cootz Invite Friends Program lets you invite friends to join Cootz ("Program"). In the event that you and your referred friend meet the criteria and complete all the steps specified in these terms, you can earn a Cash Bonus from Cootz of upto Rs. 500 ("Bonus Amount"), which Bonus Amount will be redeemable to join cash contests and contests through the Cootz’s mobile application for the iOS and/or Android mobile devices ("Cootz Application") and your friend will receive discount coupons worth Rs. 200 ( “Invitee Discount”). To participate in the Program, please note our terms and conditions ("Terms") in this respect, as they govern your participation in the Program:
	Eligibility – All users who: (i) have an account registered with Cootz'sedutainment platform ("Platform"); and (ii) have downloaded and installed the Cootz Application on their respective mobile devices, will be eligible to participate in the Program. Participation in the Program by proxy is not permitted.
	Participation in the Program is voluntary. A user shall not register or operate more than one user account with Cootz and shall not participate in the Program with more than one user account with Cootz.
	.
	By participating in the Program, you agree to and accept these Terms.<br/>
	For the purpose of participation in the Program, you are required to have the Cootz Application downloaded and installed on your mobile device. Through the Cootz Application, you will be provided with a unique link or code, which can be shared by you ("Inviter") with friends (each an "Invitee") for the purpose of inviting such friends to create and register an account with Cootz and download the Cootz Application. On receiving the link or code from the Inviter, the Invitee may either: (i) Click on the link, consequent to which such Invitee will be directed to a registration page and will be provided the option to register an account with Cootz and download and install the Cootz Application on his/her device; or (ii) download and install the Cootz Application on his/her device independently, register for a Cootz account through the Cootz Application and enter the unique code shared by the Inviter where prompted in the Cootz Application.
	The Inviter and the Invitee will be eligible to earn the Bonus Amount subject to (amongst other terms specified in these Terms): (i) the Invitee not being an existing user of Cootz; and (ii) the Invitee successfully registering for an account with Cootz through the unique link or by using the unique code shared by the Inviter; and (iv) the Inviter and Invitee agreeing to the license agreement for the Cootz Application and downloading and installing the Cootz Application as available for the Inviter’s and Invitee’s respective mobile devices. For the purposes of these Terms, an 'existing user of Cootz' shall mean a user who presently operates an account with the Platform or operated an account with the Platform at any point of time.
	An Invitee who is an existing user of Cootz is not permitted to register a new account with the Platform for the purpose of availing of the Bonus Amount. Cootz will determine in its sole discretion whether an Invitee is an existing user of Cootz or not and take appropriate action.
	In the event that the Invitee opts to register for a Cootz account through the Cootz Application, the Invitee can verify his/her contact information at the time of registration.
	The verification process may require an Inviter/Invitee to submit personal information about the user (Inviter/Invitee) and documents identifying the Inviter/Invitee. The Inviter agrees to receive communication from Cootz and to allow Cootz to communicate with Invitees referred by you about the Inviter's participation in the Program. Any information collected in respect of the Inviter/Invitee as part of the Program or otherwise in the course of such person's use of the Website shall be subject to Cootz's Privacy Policy (available here:.Cootz.com/in/privacypolicy for Indian users)
	The verification of an Inviter/Invitee shall be completed at the time of first withdrawal by the Inviter/Invitee from the Inviter’s/Invitee's 'Winnings' Account with the Platform. An Inviter/Invitee may voluntarily seek verification of the Inviter/Invitee by clicking on the 'Verify Now' tab on the Winnings tab on the My Balance Page of the Inviter/Invitee's account with the Platform.
	
    <table className={styles.table}>
        <thead>
            <tr>
                <th>Promotion</th>
                <th>When</th>
                <th>What</th>
                <th>Amount</th>
                <th>Validity</th>
            </tr>
        </thead>
            <tbody>
                <tr>
                    <td>Promotion No.1</td>
                    <td>Right after registration</td>
                    <td>75% off on contests above 1000 member</td>
                    <td>Rs.33 Single Use</td>
                    <td>7 days from the date of registration</td>
                </tr>
                <tr>
                    <td>Promotion No.2</td>
                    <td>Upon successful utilisation of Promotion No.1</td>
                    <td>50% off on contests above 1000 member</td>
                    <td>Rs.33 Single Use</td>
                    <td>7 days from the date of utilisation of first offer</td>
                </tr>
                <tr>
                    <td>Promotion No.3</td>
                    <td>Upon successful utilisation of Promotion No.2</td>
                    <td>25% off on contests above 1000 member</td>
                    <td>Rs.33 Single Use</td>
                    <td>7 days from the date of utilisation of second offer</td>
                </tr>
                <tr>
                    <td>Promotion No.4</td>
                    <td>Upon successful utilisation of Promotion No.3</td>
                    <td>10% off on all contests</td>
                    <td>Rs.101 Multiple Use</td>
                    <td>Use	14 days from the date of utilisation of third offer</td>
                </tr>
            </tbody>
    </table>

	



				

				
	Inviter Bonus a) In order for an Inviter to be eligible to earn the Bonus Amount due to him/her ("Inviter Bonus"), the Inviter must also download and install the Cootz Application on his/her mobile device. b) The credit of the Inviter Bonus is contingent on the Invitee's deposit of any Amount in its Unutilised Account and using such amounts to participate in cash contests on the Platform. Bonus upon the successful completion of such contest. c) Subject to the provisions of a) and b) above, the applicable Inviter Bonus earned by the Inviter shall be credited to the Inviter's Cash Bonus Account within fifteen (15) days of the completion of the cash contest . d) It is clarified that the Inviter will be eligible to receive the Inviter Bonus with respect to any contests only in the event the winners are declared for such contests. In the event that the contest entry amount paid by the Invitee is refunded to the Invitee with respect to any cash contest, for any reason, the Inviter shall not be eligible to receive any Inviter Bonus for such contest or contest.
	The Bonus Amounts credited to the Inviter can be used by the Inviter/ to join cash contests and contests offered by Cootz through the Platform. No part of the Bonus Amount may be used to join private contests or be withdrawn or transferred to any other cash balance account held by the Inviter/Invitee with Cootz or to any third party account or to any bank/payment instrument account. THE BONUS AMOUNT SHALL EXPIRE AND BE WITHOUT EFFECT AT THE END OF FOURTEEN DAYS FROM THE DATE OF CREDIT OF THE BONUS AMOUNT.
	The deposit of the Bonus Amount shall be at the sole discretion of Cootz and shall be subject to the Inviter’s/Invitee’s compliance with these Terms. Cootz may substitute or change the Bonus Amount offered under the Program at any time without notice. An Inviter may not substitute the amount of Bonus Amount or substitute offering for other items or exchange for cash.
	Cootz reserves the right to:
 .	withhold the deposit of the Bonus Amount; and/or
<p>a.	forfeit any deposited Bonus Amount to an Inviter/Invitee or any prizes/winnings earned by the participant by using such Bonus Amount; and/or</p>
<p>b.	deactivate the accounts of the Inviter/Invitee, in the event that it determines or reasonably believes that such Inviter/Invitee has violated these Terms or the terms and conditions of the Cootz.
	Mere participation in the Program does not entitle the Inviter to receive any Bonus Amount.
	Cootz may, at its sole and absolute discretion, disqualify any Inviter/Invitee if such Inviter/Invitee engages in or it is found that such Inviter/Invitee has engaged in any illegal, unlawful or improper conduct (with regard to the Program or otherwise).
	The decision of Cootz will be final and binding with regard to the Program, and the deposit of the Bonus Amount and the Invitee Discount and no correspondence, objection, complaints, etc. will be entertained in this regard.
	This Program cannot be clubbed with any other contests/promotions/programs that are running simultaneously and organised or conducted by Cootz.
	Cootz reserves the right to change/modify/or withdraw the Program and/or change these terms and conditions without any prior notice of the same at its sole discretion.
	The Terms and Conditions, as applicable to the Cootz's contest and services, will apply to and govern the Program.
	Cootz does not make any commitment, express or implied, to respond to any feedback, suggestion and, or, queries of the participants (Inviter/Invitee) of the Program.
	Notwithstanding anything contained herein, the aggregate liability of Cootz to a participating Inviter/Invitee in relation to the Program for any reason whatsoever shall not exceed Rs.200.</p>


        </div>
            
    )
}
