import './App.css';
import React, { useEffect, useState } from 'react';
import Hero from './Components/Hero';
import Provide from './Components/Provide';
import AppDetails from './Components/AppDetails';
import Details from './Components/Details';
import Footer from './Components/Footer';
import Exams from './Components/Exams';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Contests from './Components/Contests';
import Nav from './Components/Nav';
import Blogs from './Components/Blogs';
import BlogDetails from './Components/BlogDetails';
import ExamDetails from './Components/ExamDetails';
import Teachers from './Components/Teachers';
import { BlogsContext } from "./Context/BlogsContext"
import { ExamsContext } from "./Context/ExamsContext"
import { ContestsContext } from "./Context/ContestsContext"
import axios from 'axios';
import { Helmet } from "react-helmet";
import About from './Components/About';
import Privacypolicy from './pages/privacypolicy';
import Careers from './pages/Careers';
import ScholarshipForm from './pages/ScholarshipForm';
import Contact from './Components/Contact';

function Home() {
  return <React.Fragment>
    <Hero />
    <Provide />
    <AppDetails />
    <Details />
  </React.Fragment>
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  const [blogs, setBlogs] = useState([])
  const [exams, setExams] = useState([])
  const [contests, setContests] = useState([])

  let _getAllBlogs = async () => {
    let res = await axios.get(process.env.REACT_APP_URL + 'getAllBlogs')
    if (res.status == 200) {
      setBlogs(res.data)
    }
  }

  let _getAllExams = async () => {
    let res = await axios.get(process.env.REACT_APP_URL + 'getAllExams')
    if (res.status == 200) {
      setExams(res.data)
    }
  }


  let _getAllContests = async () => {
    let res = await axios.get(process.env.REACT_APP_PRO + "getWebsiteContests")
    if (res.status == 200) {
      setContests(res.data)
    }
  }

  useEffect(() => {
    _getAllBlogs()
    _getAllContests()
    _getAllExams()
  }, [])

  return <React.Fragment>
    {/* for meta tags ---------- */}
    <Helmet>
      <title>Cootz | HOME</title>
      <meta
        name="description"
        content="Cootz is World’s First Edutainment platform that conducts online tests for various competitive exams in contest form where users will win real cash. Cootz (Cash Out Of Test Zone) brings you a supplement to your current academic activities with a mission to reroute every competitor to learning via contest test. Our aim is to improve academic performance, nullify test series burden and to improvise the Edutainment Sector."
      />
      <meta name="keywords" content="
            Cootz,
            Cootz app,
            Cootz login,
            Cootz app download,
            Cootz student portal,
            Cootz website,
            Cootz download,
            Cootz company,
            About cootz,
            Cootz logo,
            Cootz india,
            Cootz premier league,
            Cootz RRB PL,
            Cootz RRB premier league,
            Cootz SSC PL,
            Cootz SSC premier league,
            Cootz trivia,
            Cootz TPL,
            Cootz trivia premier league,
            Fun tests,
            Edutainment platform,
            Cootz app for PC,
            Cootz contest,
            Cootz league,
            Cootz official website,
            Cootz app free,
            Cootz online,
            Cootz mock test,
            Mock test,
            Test series,
            Online mock test,
            Online test series,
            Cootz ticket,
            Cootz jackpot,
            Exam preparation,
            Cootz trailer,
            Edutainment,
            Elearning,
            Esport,
            Elearning platform,
            Gamification,
            Gamifying,
            Online education platform,
            Trivias,
            Quizzes,
            Test Series,
            Subscription free platform,
            Subscription free,
            Less Susbscription,
            No Subscription,
            Online learning platform,
            Subscription free test series,
            Subscription less test series,
            Subscription less mock test,
            Subscription free mock test,
            Cootz trivias,
            Cootz Quizzes,
            Easy Quiz,
            Tough Quiz,
            Hard Quiz,
            Easy Level Quiz,
            Tough Level Quiz,
            Hard Level Quiz,
            Entertaining Quiz,
            Entertaining Trivias,
            Elearning app,
            Elearning website,
            Elearing portal,
            Edutainment app,
            Edutainment website,
            Edutainment portal,
            Gamification app,
            New elearning app,
            UPSC preparation,
            SSC preparation,
            CGL preparation,
            RRB preparatio,
            Banking preparation,
            PO preparation,
            Clerk preparation,
            IIT preparation" />
    </Helmet>
    {/* ---------------------- */}
    <ContestsContext.Provider value={{ contests, setContests }}>
      <BlogsContext.Provider value={{ blogs, setBlogs }}>
        <ExamsContext.Provider value={{exams,setExams}} >
        <Nav />
        <div style={{ minHeight: 'calc(100vh - 50px' }}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog/:slug" element={<BlogDetails />} />
            <Route path="/exam/:slug" element={<ExamDetails />} />
            <Route path="/exams" element={<Exams />} />
            <Route path="/contests" element={<Contests />} />
            <Route path="/blogs" element={<Blogs />} />
            {/* <Route path="/teachers" element={<Teachers />} /> */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacypolicy />} />
            <Route path="/careers" element={<Careers />} />
            {/* <Route path="/scholarship" element={<ScholarshipForm />} /> */}
          </Routes>
        </div>
        <Footer />
        </ExamsContext.Provider>
      </BlogsContext.Provider>
    </ContestsContext.Provider>
  </React.Fragment>
}

export default App;
