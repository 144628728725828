import React, { useContext, useEffect, useState } from "react"
import styles from "./Details.module.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import person1 from "../Assets/person1.png"
import person2 from "../Assets/person2.png"
import instagram from "../Assets/instagram.png"
import linkedin from "../Assets/linkedin.png"
import youtube from "../Assets/youtube.png"
import twitter from "../Assets/twitter.png"
import telegram from "../Assets/telegram.png"
import news1 from "../Assets/news1.png"
import news2 from "../Assets/news2.png"
import news3 from "../Assets/news3.png"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Icon } from '@iconify/react';
import { BlogsContext } from "../Context/BlogsContext";
import { useNavigate } from "react-router";
import axios from "axios";
import avatar from "../Assets/avatar.png"
import placeholderImage from "../Assets/placeholder.webp"
import { ExamsContext } from "../Context/ExamsContext";

function Details() {

    const [testimonials, setTestimonials] = useState([])
    const {exams, setExams} = useContext(ExamsContext)
    const navigate = useNavigate()

    
    const _getAllTestimonials = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllTestimonials')
        if(res.status == 200){
            setTestimonials(res.data)
        }
    }

    exams.sort(function(a,b){
        return new Date(b.createdAt) - new Date(a.createdAt);
    });

    useEffect(()=>{
        _getAllTestimonials()
    },[])

    return <React.Fragment>
        <div className={styles.testimonials}>
        <h3>Testimonials</h3>
        <div className={styles.testimonialsContainer} style={{maxWidth:'70%',margin:'0 auto'}}>
        <Splide options={{perPage:testimonials.length == 1 ? 1 : 2}}>
        {testimonials.length == 0 ? <p style={{display:'block',margin:'0 auto',color:'#a4a4a4'}}>No testimonials available!</p> :
        testimonials.map(test => {
            return <SplideSlide>
            <div className={styles.slide}>
                <p>"{test.body}"</p>
                <div className={styles.slideFooter}>
                <img style={{objectFit:'contain'}} src={test.mediaUrl !== '' ? test.mediaUrl : avatar } />
                <p>{test.name}</p>
                </div>
            </div>
           </SplideSlide>
        })
        }
        </Splide>
        </div>
        </div>

        <div className={styles.socials}>
            <h3>Visit Our Social Channels <br/>
                For More Interactions</h3>

        <div>
            <a href="https://youtube.com/channel/UCL6lJKyY_v527cNUZuYIENA" style={{border: '1.8px solid #FF0000'}}><img src={youtube} /> <p>Youtube</p></a>
            <a href="https://www.linkedin.com/company/cootz" style={{border: '1.8px solid #44A5FF'}}><img src={linkedin} /> <p>LinkedIn</p></a>
            <a href="https://instagram.com/cootz_india?utm_medium=copy_link" style={{border: '1.8px solid #B01C87'}}><img src={instagram} /> <p>Instagram</p></a>
            <a href="https://twitter.com/cootzindia?s=20&t=N2foJl0DAkw9mpmbWiG5zw" style={{border: '1.8px solid #55ACEE'}}><img src={twitter} /> <p>Twitter</p></a>
            <a href="https://t.me/cootztriva01" style={{border: '1.8px solid #40B3E0'}}><img src={telegram} /> <p>Telegram</p></a>
        </div>
        </div>

        <div className={styles.news}>
        <h3>Latest News</h3>

        {exams.length == 0 ? <p style={{color:'#a4a4a4',textAlign:'center'}}>No latest news!</p> :
        <div className={styles.cards}>
            {exams.length !== 0 && exams.slice(0,3).map((exam,index) =>{
               return <div className={styles.card}>
                <img src={!exam.thumbnail || exam.thumbnail == '' ? placeholderImage : exam.thumbnail} />
                <div className={styles.cardBody}>
                    <h2>{exam.title}</h2>
                    <p>{exam.shortDesc}</p>
                    <a onClick={()=>navigate(`/exam/${exam.slug}`)}>Read More</a>
                </div>
                <label>{index+1}</label>
            </div>
            })}
         </div>}
         </div>
        
       {/* faqqqq */}

       <Accordion className={styles.faq} allowMultipleExpanded={true}>
        <h3>FAQ</h3>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn} >
                    What is Cootz?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    Cootzindia is World's ONLY Esports-Exam preparation platform that aims to make the learning process enjoyable and interesting. In short, Cootz is gamifying education.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    Why gamify the learning process?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    The traditional rote learning method has failed in creating enough interest and is therefore not an effective way of teaching the present generation of learners. On the contrary gamification of learning has some alluring advantages that you must know.
                    <br/>
                    <br/>1. Increases problem solving ability.
                    <br/>2. Boosts mood.
                    <br/>3. Enhances thinking ability.
                    <br/>4. Increases persistence
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    How is Cootz different from other learning platforms?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    Cootz is empowering students preparing for different competitive exams by enabling them to give test series and win exciting cash rewards for their good performance.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    Which competitive exams are covered under Cootz?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    Join us for UPSC exams, banking exams( SBI PO 2021, IBPS Clerk 2021, IBPS PO 2021, RBI Assistant, IBPS RRB Clerk, IBPS RRB PO, SBI Clerk, RBI Grade B , NABARD, NIACL AO, LIC, SEBI, JAIIB), Railways exams(DFCCIL, RRBNTPC, Group D) and SSC exams.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    How are contests designed on Cootz?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    Contest questions are designed strictly in accordance with the latest syllabus and exam pattern of various competitive exams keeping both the interest and knowledge factor intact.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    What are buy-sell contests?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    Contests in Cootz are broadly divided into
                    <br/>
                    <br/>
                    – Fixed joining fees and prize pool contest<br/>
                    – Buy Sell Contest
                    <br/>
                    <br/>

                    Buy-Sell Contest has been modelled on the stock investment setup where you can make profits by selling Contest tickets once the price of ticket goes up.<br/><br/>

                    The buy-sell contest has two variables–<br/>
                    Joining fees and winning amount.
                    <br/>
                    <br/>

                    The joining fees starts at let's say Rs. 10 but once 40% tickets are sold this fees hikes up.<br/><br/>

                    Now selling the ticket brought at Rs. 10 at a higher price can bring you profit.<br/><br/>

                    Whether the price goes up or down is determined by CQ Index.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
                    <AccordionItemButton className={styles.faqBtn}>
                    How can Cootz help you if you are a teacher teaching for competitive exams?
                    <Icon icon="ant-design:plus-outlined" color="#458dfc" width="35" height="35" />
                    </AccordionItemButton>
                <AccordionItemPanel>
                    <p>
                    We collaborate with experienced teachers in the field of competitive exam preparation arena to establish a mutually beneficial relationship for the long term. 
                    <br/>
                    We help teachers in digitising the tests they prepare for students without any cost.
                    We display their profile on our platform helping them reach more students and we provide incentives for bringing students to our platform.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

       {/* ------- */}
        
    </React.Fragment>
}

export default Details