import styles from "./Careers.module.css"
import headerImg from "../Assets/careersHeader.png"
import footerImg from "../Assets/careersFooter.png"
import lifeAtCootz from "../Assets/lifeAtCootz.png"
import card1 from "../Assets/careerCard1.png"
import card2 from "../Assets/careerCard2.png"
import card3 from "../Assets/careerCard3.png"
import ReactSelect from "react-select"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import office from "../Assets/office.jpeg"


function Careers() {

    const openRef = useRef()

    const [positions, setPositions] = useState([])
    const [position, setPosition] = useState('')
    const [image, setImage] = useState('')
    const [apply, setApply] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const [success, setSuccess] = useState(false)

    let _getAllPositions = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + "getAllPositions")
        if(res.status == 200){
            setPositions(res.data);
            setPosition(res.data[0])
        }
    }

    const uploadToCloud = async (image) => {
        const data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "jonnulfj")
        data.append("api_key", "989712982943697");
        data.append("cloud_name", "cootzz");
        let res = await axios.post("https://api.cloudinary.com/v1_1/jonnulfj/raw/upload", data)
        if(res.status == 200){
            setImage(res.data.url);
            setUploaded(true)
        }
    }

    let editHandler = async (e) => {
        e.preventDefault()
        if(image !== ''){
        let data = {
            name: position.title,
            minimumQualifications: position.minimumQualifications,
            preferredQualifications: position.preferredQualifications,
            responsibilities: position.responsibilities,
            resume: image
        }
        let res = await axios.put(process.env.REACT_APP_URL + "editPosition/" + position._id, data)
        if (res.status == 200) {
            setUploaded(false)
            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)
            }, 3000);
        }}
    }

    useEffect(()=>{
        _getAllPositions()
    },[])

    return <div>
        <div className={styles.headerContainer}>
        <div className={styles.header}>
            <div>
            <h1>Let’s create the future <br/>of edutainment together</h1> 
             <p>Have a look at our workspace and transform your <br/>ideas into our business goals and sucess.</p>
            <a href="#openings"><button>View all openings</button></a>
            </div>
            <img src={headerImg} />
        </div>
        </div>

        <div className={styles.life}>
            <h1>Life at Cootz</h1>
            <p>
            You will have the opportunity to work in a healthy setting with occasional light parties at Cootz (as an essential part of office culture).
            Though we work efficiently, we at Cootz like sharing, caring, assisting, and lifting others.
            Cootz focuses on staff growth and learning through their engaging content creation process.
            </p>
            <img src={office} />
        </div>

        <div id="openings" ref={openRef} className={styles.openings}>
        <h1>Find Job Openings</h1>
        {positions.length == 0 ?  <p style={{textAlign:'center'}}>We do not have any openings yet!</p> : <div className={styles.openingsLayout}>
            <div className={styles.positionDropdown}>
             <ReactSelect options={positions.map(p => {
                 return {label:p.title,value:p}
             })} placeholder={position.title} onChange={(e)=>{
                 setApply(false)
                 setPosition(e.value)
             }}/>
            </div>
            <div className={styles.positionList}>
                {positions.length !== 0 && positions.map(p => {
                    return <h2 onClick={()=>{
                        setPosition(p)
                        setApply(false)
                    }} className={p._id == position?._id ? styles.activeJob : ''}>{p.title}</h2>
                })}
            </div>
            {!apply ? <div>
                <h3>Minimum Qualifications</h3>
                 <p dangerouslySetInnerHTML={{__html: position.minimumQualifications}}></p>
                <h3>Preferred Qualifications</h3>
                <p dangerouslySetInnerHTML={{__html: position.minimumQualifications}}></p>
                <h3>Responsibilities</h3>
                <p dangerouslySetInnerHTML={{__html: position.responsibilities}}></p>
                <button onClick={()=>{
                    setApply(true)
                    openRef.current.scrollIntoView()
                }} className={styles.applyBtn}>Apply Fast</button>
            </div> : <div className={styles.apply}>
                <h2>Upload your resume:</h2>

                <div>
                <label for="file-upload" >
                  <div className={styles.customFileUpload}>
                  <i class="fa-solid fa-paperclip"></i> Upload Resume
                  </div>
                 </label>
                <input 
                 onChange={(e)=>uploadToCloud(e.target.files[0])}
                 id='file-upload' className={styles.inputFile} type="file" ></input>
                </div>

                {uploaded ? <p>Uploaded Successfully!</p> : null}

                <button onClick={(e)=>editHandler(e)} className={styles.applyBtn}>Submit</button>
                {success && <p className={styles.success}>Applied Successfully!</p>}
            </div>}
        </div>}
        </div>

        <div className={styles.footer}>
            <img src={footerImg} />
            <div className={styles.headerContent}>
                <h1>Why you should join us?</h1>
                <div className={styles.headerCards}>
                    <div>
                        <h2>Best Educative <br/>Environment</h2>
                        <img src={card1} />
                    </div>
                    <div>
                        <h2>Best Working <br/>Culture</h2>
                        <img src={card2} />
                    </div>
                    <div>
                        <h2>Helpful Office<br/> Environment</h2>
                        <img src={card3} />
                    </div>
                </div>
            </div>
            
        </div>
        
    </div>
}

export default Careers