import styles from "./Blogs.module.css"
import searchImg from "../Assets/search.png"
import bookmark from "../Assets/bookmark.png"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router"
import axios from "axios";
import moment from "moment"
import Loader from "./Loader"
import {Helmet} from "react-helmet";
import placeholderImage from "../Assets/placeholder.webp"
import { logEvent } from "firebase/analytics"
import { analytics } from "../Analytics/Analytics"
let title = "Cootz | BLOGS"

function Blogs() {

    let [blogs, setBlogs] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    let navigate = useNavigate()

    function Card (props) {
        return <div style={{marginBottom:'60px'}} onClick={props.onClick}>
           <div className={styles.card}>
            <img src={!props.thumbnail || props.thumbnail == '' ? placeholderImage : props.thumbnail} />
            <div className={styles.title}>
                <h3>{props.title}</h3>
                <h4>{props.shortDesc}</h4>
            </div>
        </div>
        <div className={styles.c}>
        <p>{props.date}</p>
        <img className={styles.bookmark} src={bookmark} />
        </div>
        </div>
    }

    let _getAllBlogs = async () => {
        setLoading(true)
        let res = await axios.get(process.env.REACT_APP_URL + 'getAllBlogs')
        if(res.status == 200){
            setLoading(false)
            setBlogs(res.data)
        }
    }

    useEffect(()=>{
        _getAllBlogs()
        logEvent(analytics, 'blogs_page_view', {
            page_title: title,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
    },[])

    return <div className={styles.blogs}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
         <form className={styles.search}>
                <input value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search for blogs..." type="text" />
                <button onClick={(e)=>e.preventDefault()} type="submit"><img src={searchImg}/></button>
        </form>

        {loading ? <Loader /> :
        <div className={styles.cards}>
           {blogs.filter(i => i.status == 'publish').length == 0 ? <p style={{color:'#a4a4a4'}}>No blogs posted!</p> : blogs.map(blog => {
               if(blog.status == 'publish'){
                return blog.title.toLowerCase().includes(search.toLowerCase()) || search == '' ? <Card onClick={()=>navigate(`/blog/${blog.slug}`)} thumbnail={blog.thumbnail} title={blog.title} date={moment(blog.createdAt).format('DD/MM/YYYY')} shortDesc={blog.shortDesc.substr(0,100)}  /> : null
           }})}
        </div>}


    </div>
}

export default Blogs