import React, { useContext, useEffect, useState } from "react"
import styles from "./Contests.module.css"
import searchImg from "../Assets/search.png"
import {Helmet} from "react-helmet";
import { ContestsContext } from "../Context/ContestsContext"
import placeholderImage from "../Assets/placeholder.webp"
import { logEvent } from "firebase/analytics";
import { analytics } from "../Analytics/Analytics";
import moment from "moment";

let title = "Cootz | CONTESTS"


function Contests() {

    let {contests, setContests} = useContext(ContestsContext)

    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    function Card (props) {
        return <div className={styles.card}>
            <img src={props.image} />
            <div className={styles.title}>
                <h3>{props.title}</h3>
                <a href="#">Play</a>
            </div>
        </div>
    }

    useEffect(()=>{
        logEvent(analytics, 'contests_page_view', {
            page_title: title,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
    },[])

    return <div className={styles.contests}>

        <Helmet>
            <title>{title}</title>
        </Helmet>

            <form className={styles.search}>
                <input value={search} onChange={(e)=>setSearch(e.target.value)} placeholder="Search for contests..." type="text" />
                <button onClick={(e)=>e.preventDefault()} type="submit"><img src={searchImg}/></button>
            </form>

           
        <div className={styles.heading}>
            <h2>POOL CONTESTS</h2>
            <div></div>
        </div>

        <div className={styles.bottomCards}>            
            {contests?.pool?.length !== 0 && contests?.pool?.map(i => {
                return i.contestName.toLowerCase().includes(search.toLowerCase()) || search == '' ?
                 <Card image={i.img == '' || i.img == 'imageurl' ? placeholderImage : i.img} title={i.contestName} />
                  : null
            })}
         </div>

         <div className={styles.heading}>
            <h2>ONE VS ONE CONTESTS</h2>
            <div></div>
         </div>

         <div className={styles.bottomCards}>            
            {contests?.onevone?.length !== 0 && contests?.onevone?.map(i => {
                return i.contestName.toLowerCase().includes(search.toLowerCase()) || search == '' ?
                 <Card image={i.img == '' || i.img == 'imageurl' ? placeholderImage : i.img} title={i.contestName} />
                  : null
            })}
         </div>
    </div>
}

export default Contests