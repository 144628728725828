import React, { useContext, useEffect, useState } from "react";
import styles from "./Hero.module.css"
import background from "../Assets/headerBackground.png"
import inviteBtn from "../Assets/inviteBtn.png"
import downloadBtn from "../Assets/downloadBtn.png"
import thunder from "../Assets/thunder.png"
import female from "../Assets/female.png"
import label from "../Assets/label.png"
import image1 from "../Assets/image1.png"
import image2 from "../Assets/image2.png"
import image3 from "../Assets/image3.png"
import image4 from "../Assets/image4.png"
import bottomright from "../Assets/bottomright.png"
import bottomleft from "../Assets/bottomleft.png"
import topright from "../Assets/topright.png"
import topleft from "../Assets/topleft.png"
import downloadIcon from "../Assets/downloadIcon.png"
import inviteIcon from "../Assets/inviteIcon.png"
import {ContestsContext} from "../Context/ContestsContext"
import {Helmet} from "react-helmet";
import placeholderImage from "../Assets/placeholder.webp"
import moment from "moment";
import { logEvent } from "firebase/analytics"
import { analytics } from "../Analytics/Analytics"
let title = "Cootz | HOME"

function Hero () {

    let {contests, setContests} = useContext(ContestsContext)

    function Card (props) {
        return <div className={styles.card}>
            <img src={props.image} />
            <div className={styles.title}>
                <h3>{props.title}</h3>
                <a href="#">Quiz</a>
            </div>
        </div>
    }

    useEffect(()=>{
        logEvent(analytics, 'home_page_view', {
            page_title: title,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
    },[])

    return <div className={styles.hero}>
        <Helmet>
            <title>{title}</title>
            <meta name="keywords" content="
            Cootz,
            Cootz app,
            Cootz login,
            Cootz app download,
            Cootz student portal,
            Cootz website,
            Cootz download,
            Cootz company,
            About cootz,
            Cootz logo,
            Cootz india,
            Cootz premier league,
            Cootz RRB PL,
            Cootz RRB premier league,
            Cootz SSC PL,
            Cootz SSC premier league,
            Cootz trivia,
            Cootz TPL,
            Cootz trivia premier league,
            Fun tests,
            Edutainment platform,
            Cootz app for PC,
            Cootz contest,
            Cootz league,
            Cootz official website,
            Cootz app free,
            Cootz online,
            Cootz mock test,
            Mock test,
            Test series,
            Online mock test,
            Online test series,
            Cootz ticket,
            Cootz jackpot,
            Exam preparation,
            Cootz trailer,
            Edutainment,
            Elearning,
            Esport,
            Elearning platform,
            Gamification,
            Gamifying,
            Online education platform,
            Trivias,
            Quizzes,
            Test Series,
            Subscription free platform,
            Subscription free,
            Less Susbscription,
            No Subscription,
            Online learning platform,
            Subscription free test series,
            Subscription less test series,
            Subscription less mock test,
            Subscription free mock test,
            Cootz trivias,
            Cootz Quizzes,
            Easy Quiz,
            Tough Quiz,
            Hard Quiz,
            Easy Level Quiz,
            Tough Level Quiz,
            Hard Level Quiz,
            Entertaining Quiz,
            Entertaining Trivias,
            Elearning app,
            Elearning website,
            Elearing portal,
            Edutainment app,
            Edutainment website,
            Edutainment portal,
            Gamification app,
            New elearning app,
            UPSC preparation,
            SSC preparation,
            CGL preparation,
            RRB preparatio,
            Banking preparation,
            PO preparation,
            Clerk preparation,
            IIT preparation" />
        </Helmet>
        <img className={styles.backgroundImg} src={background} />

        <div className={styles.heroUp}>

        <div style={{display:'flex',alignItems:'flex-start'}}>
            <img className={styles.thunderImg} src={thunder} />
            <div>
            <h1>India's First<br/>Edutainment Platform</h1> 
           <p className={styles.headerTitle}>Experience the thrill of Fantasy games in Education.</p>
            <p className={styles.headerTitle}>Join World's only Esports-Exam preparation platform.</p>

            <div className={styles.headerButtons}>
            <button className={styles.downloadBtn} onClick={()=>window.location.href = 'https://cootzlite.s3.ap-south-1.amazonaws.com/pro/Cootzprov1.0.0.apk'} ><img src={downloadIcon}/>Download</button>
            {/* <button className={styles.inviteBtn}><img src={inviteIcon}/><p>Send an invite</p></button> */}
            {/* <div>
                <input placeholder="Enter mobile number" type='tel' />
                <button type="submit">GET APP LINK</button>
            </div> */}
            </div>

            </div>
        </div>

        <div className={styles.heroImage}>
            <img className={styles.femaleImg} src={female} />
            <img className={styles.bottomright} src={bottomright} />
            <img className={styles.bottomleft} src={bottomleft} />
        </div>

        </div>

        <div className={styles.heroDown}>
        <div className={styles.headerCards}>
            <div>
                <h2>2 Lakh +</h2>
                <h3>Downloads</h3>
            </div>
            <div style={{backgroundColor:'#4cd4ca'}}>
                <h2>1 Cr. +</h2>
                <h3>Contests Played</h3>
            </div>
            <div>
                <h2>1000 +</h2>
                <h3>Results</h3>
            </div>
        </div>
          <div className={styles.labelContainer}>
            <div className={styles.label} >
                <img src={label} />
                <h3>Trending Contests</h3>
            </div>
            </div>

           <div className={styles.cardsContainer}>
            {contests?.trending?.length > 0 ? contests?.trending?.map(i => {
                return <Card image={!i.img || i.img == '' ? placeholderImage : i.img} title={i.contestName} /> 
            }) : <p style={{color:'#a4a4a4'}}>No trending contests!</p>}
           </div>
         </div>

         <div className={styles.headerBtn}>
             <div>AB KHEL KHEL MEIN PADH RAHA BHARAT</div>
         </div>
    </div>
}

export default Hero
