import styles from "./Footer.module.css"
import logo from "../Assets/logo.png"
import appstore from "../Assets/appstore.png"
import playstore from "../Assets/playstore.png"
import { Link } from "react-router-dom"

function Footer() {
    return <footer className={styles.footer}>
        <div>
            <button className={styles.logo}>
                <img src={logo} />
            </button>
            <p>702-704 7th Floor Amba Tower,</p>
            <p>DC chowk, Rohini Sector 9,</p>
            <p>New Delhi, Delhi 110085</p>
            <br />
            <br />
            <img onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.cootz.lite'} className={styles.appImage} src={playstore} />
        </div>
        <div>
            <Link to="/exams" href="#">Exams</Link>
            <Link to='/contests'>Contests</Link>
            <Link to="/blogs" href="#">Blogs</Link>
            <Link to="/teachers">Teachers</Link>
            <Link to="/privacy">Terms & Conditions</Link>
            <Link to="/about">About Us</Link>
            <Link to="/contact">Contact Us</Link>
            <a href="https://merchant.razorpay.com/policy/KKy4XI8zyzUBjO/shipping" style={{cursor:'pointer'}}>Shipping</a>

            {/* <a href="#">Live Mocks</a> */}
            {/* <a href="#">Mega Contests</a> */}
        </div>
    </footer>
}

export default Footer