import styles from "./AppDetails.module.css"
import image1 from "../Assets/app1.png";
import image2 from "../Assets/app2.png"
import send from "../Assets/send.png"
import appstore from "../Assets/appstore.png"
import playstore from "../Assets/playstore.png"
import reviewUser from "../Assets/reviewUser.png"
import commas from "../Assets/commas.png"
import avatar from "../Assets/avatar.png"
import { Splide, SplideSlide } from "@splidejs/react-splide";
import downloadIcon from "../Assets/downloadIcon.png"

function AppDetails () {

    function Card(props) {
        return <div className={styles.card}>
            <div className={styles.image}>
                <div className={styles.color}></div>
                <img src={props.image} />
            </div>

            <div className={styles.info}>
                <h1>{props.title}</h1>
                <p>{props.desc}</p>

                <div className={styles.headerButtons}>
                <button className={styles.downloadBtn} onClick={()=>window.location.href = 'https://cootzlite.s3.ap-south-1.amazonaws.com/pro/Cootzprov1.0.0.apk'} ><img src={downloadIcon}/>Download</button>
                {/* <button className={styles.inviteBtn}><img src={inviteIcon}/><p>Send an invite</p></button> */}
                {/* <div>
                <input placeholder="Enter mobile number" type='tel' />
                <button type="submit">GET APP LINK</button>
                 </div> */}
                </div>

                {/* <div className={styles.input}>
                    <label>Enter your number to get the download link</label>
                    <div className={styles.appForm}>
                    <input type="tel"/>
                    <button type="submit"><img src={send} /></button>
                    </div>
                </div> */}
                <div className={styles.review}>
                <h2>App Reviews</h2>
                <Splide options={{
                    type:'loop'
                }} aria-label="app reviews">
                <SplideSlide >
                <div>
                     <div className={styles.reviewCard}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                        <img className={styles.reviewUser} src={avatar} />
                        <div>
                            <p style={{fontWeight:'600'}}>Sahil Hussain</p>
                            <p style={{color:'#F48462'}}>GATE 2022 AIR 76</p>
                        </div>
                    </div>
                    <p style={{fontSize:'0.8rem',marginLeft:'22px'}}>I without a doubt propose Cootz. it'd encourage students to have a look at hard. you'll locate pattern exams here which can be a high-quality match for your exam format and will assist you put together to your competition.</p>
                    <img className={styles.commas} src={commas} />
                 </div>
                </div>
                 </SplideSlide>
                 <SplideSlide>
                 <div>
                     <div className={styles.reviewCard}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                        <img className={styles.reviewUser} src={avatar} />
                        <div>
                            <p style={{fontWeight:'600'}}>Aditya Jain</p>
                            <p style={{color:'#F48462'}}>JEE ADVANCED 2022 8000 AIR</p>
                        </div>
                    </div>
                    <p style={{fontSize:'0.8rem',marginLeft:'22px'}}>I really like your tactics, especially the active recall strategies, which were extremely useful for the trivia and mock exams. I appreciate all of your assistance.</p>
                    <img className={styles.commas} src={commas} />
                 </div>
                </div>
                 </SplideSlide>


                 <SplideSlide>
                 <div>
                     <div className={styles.reviewCard}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                        <img className={styles.reviewUser} src={avatar} />
                        <div>
                            <p style={{fontWeight:'600'}}>Satish Rajwan</p>
                            <p style={{color:'#F48462'}}>BPSC 66 450 Rank</p>
                        </div>
                    </div>
                    <p style={{fontSize:'0.8rem',marginLeft:'22px'}}>Compared to other prep presentations I've attended in the past, I liked it the best that there was so much trivia, which really aids in expanding general knowledge and makes it easy to review my syllabus. Undoubtedly, I'll perform more retrieval work.</p>
                    <img className={styles.commas} src={commas} />
                 </div>
                </div>
                 </SplideSlide>

                 <SplideSlide>
                 <div>
                     <div className={styles.reviewCard}>
                    <div style={{display:'flex',alignItems:'center',marginBottom:'20px'}}>
                        <img className={styles.reviewUser} src={avatar} />
                        <div>
                            <p style={{fontWeight:'600'}}>Amit Kumar</p>
                            <p style={{color:'#F48462'}}>SSC CGL 2021 Rank 371</p>
                        </div>
                    </div>
                    <p style={{fontSize:'0.8rem',marginLeft:'22px'}}>I found it difficult to concentrate, and I felt like the theoretical material wasn't resonating. I thought I was being deceived. I started looking for study strategies, and then I came across Cootz Edutainment. You conduct practise exams in a unique approach to ensure that information is retained.</p>
                    <img className={styles.commas} src={commas} />
                 </div>
                </div>
                 </SplideSlide>
                </Splide>
                </div>

            </div>
        </div>
    }

    return <div className={styles.container}>
            {/* <Card title="COOTZ LITE" desc="Experience the thrill of Fantasy games in 
            Education Join World's only Esports-Exam 
            preparation platform." image={image1} downloadImage={playstore} reviewerName="Apporva Singh" reviewerDesc="SSC CGL 2019 (630/700)" reviewerContent="COOTZ's simulated tests and speed tests, which I gave on their Beta Application, were quite helpful. The questions are of the same high quality as the genuine ones. I'm hoping for a fantastic edutainment platform revolution. COOTZ, best wishes." /> */}
            <Card title="COOTZ PRO" desc="Experience the thrill of Fantasy games in 
            Education Join World's only Esports-Exam 
            preparation platform." image={image2} downloadImage={appstore} />
    </div>
}

export default AppDetails