import styles from "./Provide.module.css"
import image1 from "../Assets/image21.png"
import image2 from "../Assets/image22.png"
import image3 from "../Assets/image23.png"
import gamifying from "../Assets/gamifying.png"
import rocket from "../Assets/rocket.png"
import lines from "../Assets/lines.png"
import Modal from "./Modal/Modal"
import { useState } from "react"

function Provide () {

    const [modal, setModal] = useState(false)

    return  <div className={styles.provide}>
    <div className={styles.heading}>
    <h2>What We Provide</h2>
    <p>Cootz is a one stop solution for all the competitive exams in India. You no longer need to spend lakhs in coaching centres and online test series when you can receive the best questionnaires for preparation right here in Cootz.
    </p>
    </div>

       <div className={styles.cards}>
           <div className={styles.card}>
               <img src={image1} />
               <h2>Entrance Exam <br/>Preparation</h2>
               <p>Cootz is going to help you build your understanding of various subjects not through the process of examination but through tournament modules in education. Let's become a part of this revolution now!</p>
           </div>
           <div className={styles.card}>
               <img src={image2} />
               <h2>Gaming <br/> Experience </h2>
               <p>A mind that is free and open grasps any form of knowledge in a better manner. The rote learning method fails in creating interest and is therefore not an effective way of teaching. Incorporating games in education makes the entire learning process an amazing experience for the students and for the same reason video games on various subjects like history, maths, science are becoming increasingly popular among the present generation of learners.</p>
           </div>
           <div className={styles.card}>
               <img src={image3} />
               <h2>Win Cash <br/> Rewards</h2>
               <p>Experiments and researches indicate that gamification of the learning process is far more beneficial in making concepts clear and building core understanding of various subjects. Thus, we are working to build an arena where students enter to test their knowledge of subjects and win cash rewards for performing well. Result driven incentives help students help accelerate the learning process in a step by step manner.</p>
           </div>
           {/* <img className={styles.lines} src={lines} /> */}
       </div>


       <div className={styles.heading}>
    <h2>Gamifying Education</h2>
    <p>If you have ever played a game you must have stuck at one stage that seemed very difficult to cross but those who stick to it ultimately manage to win over the hurdle. Such a practice helps develop immense persistence in a person which is one of the most crucial ingredients of success. Cootz is going to help you build your understanding of various subjects not through the process of examination but through tournament modules in education. Let's become a part of this revolution now!</p>
    </div>

    <img className={styles.gamifying} src={gamifying} />
    

    <div className={styles.heading}>
    <h2>Pace up your preparation with cootz</h2>
    <p>Let's take a quick look at the etymology of these two words to understand how closely related they are.</p>
    </div>

    <img className={styles.rocket} src={rocket} />
    <button onClick={()=>setModal(true)} className={styles.learnMoreBtn}>Learn More</button>

    {modal && <Modal header="Exam preparation with Cootz" close={()=>setModal(false)}>
        <p>
        Examination comes from the Latin term "examinare" meaning, "to weigh; to ponder, consider action of testing or judging; judicial inquiry" and tournament comes from the Anglo-French term "turnei" meaning, "a sporting competition in which contestants play a series of games to decide the winner"
        </p>
        <p>Thus the aim of both examination and tournament is to test the ability of a group of individuals keeping certain parameters and ultimately deciding a winner and providing rank based on performance. Quite interestingly, on analysing deeply we find that there's a significant difference in how a tournament can help an individual and how an examination can help an individual since the basis of these two terms vary in so many respects.
        </p>
        <p>Firstly talking about examinations, they are short in duration and have a specific syllabus. Little can be expected out of it and it is one of the oldest ways of judging the abilities or knowledge of a student. The entire design of the examination has some severe flaws such as the role of luck. A person who has completed eighty-five of the syllabus and has more knowledge might score less than someone who has completed just the twenty five percent of the syllabus from which questions were asked in examination. Hence, this old system lacks in too many ways.</p>
        <p>Now let's talk about tournaments. They are longer in duration than most exams and are spread across various days. Thus consistent good performance is required for an individual to win and no amount of luck plays a significant role in the tournament. Hence, this system is way more effective and efficient as compared to examinations. Adding on to the pros of the tournament is the fact that the term is closely associated with games and sports. Since games and sports are already way more popular among individuals of all ages this again gives a plus point to anyone wanting to choose between examination and tournament.</p>
        <p>Cootz is going to help you build your understanding of various subjects not through the process of examination but through tournament modules in education. Let's become a part of this revolution now!</p>
    </Modal>}

    </div>
}

export default Provide