import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router"
import styles from "./BlogDetails.module.css"
import {Helmet} from "react-helmet";
import { logEvent } from "firebase/analytics"
import { analytics } from "../Analytics/Analytics"
import moment from "moment";
import axios from "axios";
import placeholderImage from "../Assets/placeholder.webp"

function BlogDetails() {

    let contentRef = useRef()
    const [blog, setBlog] = useState('')
    const params = useParams()

    let _getBlogDetails = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getBlogBySlug/' + params.slug)
        if(res.status == 200){
            setBlog(res.data[0])
        }
    }

    useEffect(()=>{
        _getBlogDetails()
        logEvent(analytics, 'blog_view', {
            blog_title: blog.title,
            blog_desc: blog.shortDesc,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
          });
    },[])

    console.log(blog)

    return <div className={styles.details}>

        <Helmet>
            <title>Cootz | BLOGS</title>
            <meta
            name="description"
            content={blog.shortDesc}/>
            <meta name="keywords" content={blog.keywords || ""}/>
        </Helmet>

        <img className={styles.thumbnail} src={!blog.thumbnail || blog.thumbnail == '' ? placeholderImage : blog.thumbnail} />
        <div className={styles.body}>
        <h1 className={styles.title}>{blog.title}</h1>
        <p className={styles.shortDesc}>{blog.shortDesc}</p>

        <div dangerouslySetInnerHTML={{__html:blog.body}} className={styles.content}>
      </div>
        </div>
        
    </div>
}

export default BlogDetails