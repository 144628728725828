import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAo5SKpA8TBOhBfevVm2d4pqU8RSxENHu0",
    authDomain: "cootz-website.firebaseapp.com",
    projectId: "cootz-website",
    storageBucket: "cootz-website.appspot.com",
    messagingSenderId: "436786286993",
    appId: "1:436786286993:web:67e6830e0b1a29dfba0a30",
    measurementId: "G-8WN4LNTF2H"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app)