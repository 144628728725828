import React from "react"
import styles from "./Modal.module.css"

function Modal(props) {
    return <React.Fragment>
        <div onClick={props.close} className={styles.overlay}></div>
         <div className={styles.modal}>
        
        <h1 className={styles.modalHeader}>
            {props.header}
        </h1>
        <div className={styles.modalBody}>
        {props.children}
        </div>
        <div className={styles.modalFooter}>
        <button onClick={props.close}>OK. Got It!</button>
        </div>
    </div>
    </React.Fragment> 
}

export default Modal