import {Bars} from "react-loader-spinner";

function Loader() {
    return <div style={{
        display:'flex',
        margin:'50px 0',
        width:'100%',
        justifyContent:'center'
    }}><Bars heigth="50" width="50" color="#129FF7" ariaLabel="loading-indicator" /></div>
}

export default Loader