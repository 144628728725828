import { logEvent } from "firebase/analytics";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router"
import { analytics } from "../Analytics/Analytics";
import styles from "./ExamDetails.module.css"
import placeholderImage from "../Assets/placeholder.webp"
import axios from "axios";

function ExamDetails() {

    const params = useParams()
    const[exam, setExam] = useState('')

    let _getExamDetails = async () => {
        let res = await axios.get(process.env.REACT_APP_URL + 'getExamBySlug/' + params.slug)
        if(res.status == 200){
            setExam(res.data[0])
        }
    }

    useEffect(()=>{
        _getExamDetails()

        logEvent(analytics, 'exam_view', {
            exam_title: exam.title,
            exam_desc: exam.shortDesc,
            visit_time: moment().format("DD-MM-YYYY hh:mm A")
        });
    },[])

    return <div className={styles.details}>

        <Helmet>
            <title>Cootz | EXAMS</title>
            <meta
            name="description"
            content={exam.title}
            />
            <meta name="keywords" content={exam.keywords || ''}/>
        </Helmet>

        <div className={styles.body}>
        <img className={styles.thumbnail} src={!exam.thumbnail || exam.thumbnail == '' ? placeholderImage : exam.thumbnail} />
        <h1 className={styles.title}>{exam.title}</h1>
        <p className={styles.shortDesc}>{exam.shortDesc}</p>

        <div dangerouslySetInnerHTML={{__html:exam.details}} className={styles.content}>
            
      </div>
        </div>
        
    </div>
}

export default ExamDetails